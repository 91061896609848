import axios from "axios";
import React, { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import TransactionChart from "../../components/TransactionChart";

export default function Transaction() {
  const [chartInfo, setChartInfo] = useState(null);
  const [companylist, setCompanylist] = useState([]);

  useEffect(() => {
    (async () => {
      const fetched = await axios.get("http://stockinu.com:4000/api/company");
      setCompanylist(fetched.data);
    })();
  }, []);

  const handleChartInfo = ({ company_id, name }) => {
    setChartInfo({ company_id, name });
  };

  return (
    <div className="h-full p-4">
      <div>
        <SearchInput datalist={companylist} handleKeyword={handleChartInfo} />
      </div>

      {chartInfo ? (
        <div className={`${!chartInfo && "hidden"}  h-full`}>
          <TransactionChart chartInfo={chartInfo} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
