import axios from "axios";
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

async function getChartData(code) {
  const newdata = [];
  await axios
    .get(`http://stockinu.com:4000/api/employees/code/${code}`)
    .then((res) => {
      res.data.forEach((item) => {
        newdata.push({
          x: Date.parse(item.date), // milliseconds 단위
          y: item.employees,
        });
      });
    })
    .catch((e) => console.log(e))
    .finally(() => {});

  return newdata;
}

async function getCompanyInfo(code) {
  return await axios
    .get(`http://stockinu.com:4000/api/company?code=${code}`)
    .then((res) => {
      if (res.data.length < 1) {
        throw new Error("No Data");
      }
      return res.data[0];
    })
    .catch((err) => console.log(err));
}

export default function EmployeesChart({ code, name }) {
  const base_options = {
    // 기초스타일
    title: {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: `${name} 고용추이`,
    },
    chart: {
      // 차트 스타일 설정
      backgroundColor: "transparent",
      plotBorderWidth: 1,
      zoomType: "x",
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "명",
      },
      gridLineColor: "transparent",
    },

    tooltip: {
      borderRadius: 20,
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "#33537970",
      },
    },
  };

  const [options, setOptions] = useState(base_options);

  useEffect(() => {
    getChartData(code)
      .then((res) => {
        // chart data 셋업

        const series = [
          {
            type: "line",
            data: res,
            name: "인원수",
            color: "white",
            marker: {
              radius: 3,
              lineColor: "red",
            },
            lineWidth: 3,
          },
        ];

        const title = {
          style: {
            color: "white",
            fontWeight: "bold",
          },
          text: `${name} 고용추이`,
        };

        getCompanyInfo(code).then((companyData) => {
          console.log(companyData.marketcap);
          const subtitle = {
            text: `(시총:${companyData.marketcap}억)`,
          };
          setOptions((prev) => {
            const temp = {
              ...prev,
              series,
              title,
              subtitle,
            };
            return temp;
          });
        });
      })
      .finally(() => {});
  }, [code]);
  return (
    <div className="w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType={"chart"}
      />
    </div>
  );
}
