import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import SharesStockChart from "../../components/StockChart";
import TableContent from "../TableContent";

export default function Fstatement() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState({});
  const [data, setData] = useState([]);
  const [account, setAccount] = useState("inventory");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get(
        `http://stockinu.com:4000/api/fstatement/account/${account}`
      );
      setData(res.data);
      setIsLoading(false);
    })();
  }, [account]);

  const handleChartInfo = ({ company_id, name }) => {
    setChartInfo({ company_id, name });
  };

  const columns = useMemo(
    () => [
      { accessor: "name", Header: "회사명", disableSortBy: true },
      {
        accessor: "latest",
        Header: "이번분기(억)",
        disableFilters: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        accessor: "quarter_ago",
        Header: "전분기(억)",
        disableFilters: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        accessor: "year_ago",
        Header: "전년동분기(억)",
        disableFilters: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        accessor: "qoq",
        Header: "QoQ(%)",
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value}
            </span>
          );
        },
      },
      {
        accessor: "yoy",
        Header: "YoY(%)",
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value}
            </span>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContent
        isLoading={isLoading}
        columns={columns}
        data={data}
        handleChartInfo={handleChartInfo}
      >
        {chartInfo?.company_id ? (
          <SharesStockChart
            company_id={chartInfo?.company_id}
            name={chartInfo?.name}
          />
        ) : (
          <></>
        )}
        <form className="">
          <select
            className="bg-zinc-500 text-text text-sm font-bold rounded border my-2 py-0.5"
            value={account}
            onChange={(e) => {
              setAccount(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="inventory">재고자산변동</option>
            <option value="advanced">선수금변동</option>
            <option value="long_advanced">장기선수금변동</option>
            <option value="unearned">선수수익변동</option>
            <option value="long_unearned">장기선수수익변동</option>
            <option value="contract">계약부채변동</option>
            <option value="long_contract">장기계약부채변동</option>
          </select>
        </form>
      </TableContent>
    </>
  );
}
