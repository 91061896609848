import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ExportChart({ chartData, product }) {
  const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
      var group = el[key];
      if (carry[group] === undefined) {
        carry[group] = [];
      }
      carry[group].push(el);
      return carry;
    }, {});
  };

  const base_options = {
    // 기초스타일
    title: {
      style: {
        color: "white",
        fontWeight: "bold",
      },
    },
    chart: {
      // 차트 스타일 설정
      type: "area",
      backgroundColor: "transparent",
      plotBorderWidth: 1,
      zoomType: "x",
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "1,000$",
      },
      gridLineColor: "transparent",
    },

    tooltip: {
      borderRadius: 20,
      shared: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
      area: {
        fillOpacity: 0.1,
      },
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "#33537970",
      },
    },
  };

  const [options, setOptions] = useState(base_options);

  useEffect(() => {
    // chart data 셋업

    const revisedData = groupBy(chartData, "region");

    const data = Object.keys(revisedData).map((region) => {
      return {
        name: region,
        data: revisedData[region].map((item) => {
          return {
            x: Date.parse(item.date),
            y: item.amountDollar,
          };
        }),
      };
    });

    const series = data.map((item) => {
      return {
        data: item.data,
        name: item.name,
        // color: "orange",
        marker: {
          radius: 3,
          lineColor: "red",
        },
        lineWidth: 3,
      };
    });

    // const series = [
    //   {
    //     type: "line",
    //     data: data,
    //     name: "수출액($)",
    //     color: "orange",
    //     marker: {
    //       radius: 3,
    //       lineColor: "red",
    //     },
    //     lineWidth: 3,
    //   },
    // ];

    const title = {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: `${product}`,
    };

    setOptions((prev) => {
      const temp = {
        ...prev,
        series,
        title,
      };
      return temp;
    });
  }, [chartData]);

  return (
    <div className="w-full">
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"chart"}
        />
      </div>
    </div>
  );
}
