import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID = "G-CD060NF8GD";

export default function RouterChangeTracker() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  // localhost는 기록하지 않음
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID);
      setInitialized(true);
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [initialized, location]);

  useEffect(() => {
    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID);
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [location]);

  return <div></div>;
}
