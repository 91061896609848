import axios from "axios";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as AnnotationsModule from "highcharts/modules/annotations"; // annotation 용 모듈
import TableContent from "../pages/TableContent";
require("highcharts/modules/annotations")(Highcharts);

const groupBy = function (data, key) {
  return data.reduce(function (carry, el) {
    var group = el[key];
    if (carry[group] === undefined) {
      carry[group] = [];
    }
    carry[group].push(el);
    return carry;
  }, {});
};

async function getCompanyInfo(company_id) {
  return await axios
    .get(`http://stockinu.com:4000/api/company?id=${company_id}`)
    .then((res) => {
      if (res.data.length < 1) {
        throw new Error("No Data");
      }
      return res.data[0];
    })
    .catch((err) => console.log(err));
}

async function getChartData(company_id) {
  const newdata = [];
  await axios
    .get(`http://stockinu.com:4000/api/stockprice/company_id/${company_id}`)
    .then((res) => {
      res.data.forEach((item) => {
        newdata.push({
          x: Date.parse(item.date), // milliseconds 단위
          date: item.date,
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
        });
      });
    })
    .catch((err) => console.log(err));

  return newdata;
}

async function getIncomeStatementData(company_id) {
  const newdata = {};
  const dataForIncomeTable = [];
  await axios
    .get(`http://stockinu.com:4000/api/istatement/company_id/${company_id}`)
    .then((res) => {
      const groupped = groupBy(res.data, "account");
      Object.keys(groupped).forEach((account) => {
        newdata[account] = groupped[account].map((item) => {
          return {
            x: Date.parse(item.date),
            // y: item.value,
            y: item.value !== null ? item.value : 0,
          };
        });
      });

      const grouppedDate = groupBy(
        res.data.filter((item) => item.value !== null),
        "date"
      );

      Object.keys(grouppedDate).forEach((date) => {
        let itemSaving = { date: date.slice(2, 7), sortDate: date };
        grouppedDate[date].forEach((item) => {
          const account = item.account;
          if (account === "revenue") {
            itemSaving["revenue"] = (item.value ? item.value : 0).toFixed(0);
          } else if (account === "operating_profit") {
            itemSaving["operating_profit"] = (
              item.value ? item.value : 0
            ).toFixed(0);
          } else if (account === "net_profit") {
            itemSaving["net_profit"] = (item.value ? item.value : 0).toFixed(0);
          }
        });

        dataForIncomeTable.push(itemSaving);
      });
    })
    .catch((err) => console.log(err));
  dataForIncomeTable.sort((a, b) => {
    return -Date.parse(a.sortDate) + Date.parse(b.sortDate);
  });

  return [newdata, dataForIncomeTable];
}

async function getAnnotationData(company_id, pathname, person = null) {
  if (pathname !== "massshares" && pathname !== "shares" && pathname !== "") {
    return [];
  }
  const url = `http://stockinu.com:4000/api/${
    pathname !== "massshares" ? "shares" : "massshares"
  }?company_id=${company_id}${
    person !== null ? "&shares.person=" + person : ""
  }`;

  let result = [];
  await axios
    .get(url)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => console.log(err));

  return result;
}

const base_options = {
  // 기초스타일

  chart: {
    // 차트 스타일 설정
    backgroundColor: "transparent",
    zoomType: "x",
    height: `${(6 / 12) * 100}%`,
  },

  accessibility: {
    enabled: false,
  },
  legend: {
    itemStyle: {
      color: "white",
      fontSize: "0.8rem",
    },
    itemHiddenStyle: {
      color: "gray",
    },
    enabled: true,
  },
  xAxis: {
    labels: {
      format: "{value:%y-%m-%d}",
    },
    crosshair: true,
  },

  yAxis: [
    {
      title: {
        // 주가차트
        text: "주가(원)",
        style: {
          color: "white",
        },
      },
      gridLineColor: "#333232",
      opposite: false,
      labels: {
        formatter: ({ value }) => {
          return ` ${
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
          }`;
        },
        style: {
          color: "white",
        },
      },
      crosshair: true,
    },
    {
      title: {
        // 주가차트
        text: "실적(억)",
        style: {
          color: "white",
        },
      },
      gridLineColor: "#333232",
      opposite: true,
      labels: {
        formatter: ({ value }) => {
          return ` ${
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "억"
          }`;
        },
        style: {
          color: "white",
        },
      },
      crosshair: true,
    },
  ],

  plotOptions: {
    series: {
      turboThreshold: 0,
    },
    candlestick: {
      lineColor: "#67E8F9",
      color: "#67E8F9",
      upLineColor: "#FB7185", // docs
      upColor: "#FB7185",
    },
  },
  tooltip: {
    borderRadius: 20,
    outside: true,

    // xDateFormat: "%Y-%m-%d",
    shared: true,
  },
  navigator: {
    height: 20,
  },

  rangeSelector: {
    buttonTheme: {
      // styles for the buttons
      fill: "none",
      stroke: "none",
      "stroke-width": 0,
      r: 8,
      style: {
        color: "white",
        fontWeight: "bold",
      },
      states: {
        hover: {},
        select: {
          fill: "white",
          style: {
            color: "#039",
          },
        },
        // disabled: { ... }
      },
    },
  },
  series: [
    {
      type: "candlestick",
    },
  ],
};

export default function StockChart({ company_id, name, person = null }) {
  const [options, setOptions] = useState(base_options);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const [incomes, setIncomes] = useState();
  useEffect(() => {
    setIsLoading(true);
    const start = async () => {
      const companyInfo = await getCompanyInfo(company_id);
      await getChartData(company_id).then((res) => {
        // chart data 셋업

        let series = [
          {
            type: "candlestick",
            data: res,
            events: {
              click: function (e) {
                console.log(e);
              },
            },
            name: "주가",
            zIndex: 9999,
          },
        ];
        let title = {
          text: `${name}
          <span style="font-size: 0.8rem; color: gray"> (${(
            companyInfo.marketcap ?? 0
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}억, PER:${
            companyInfo.per === 0 ? "-" : companyInfo.per
          }, PBR:${companyInfo.pbr} )</span>`,
          style: {
            color: "white",
            fontWeight: "bold",
          },
          useHTML: true,
        };

        const events = {
          load: function () {
            const end = res[res.length - 1].x;
            const start = end - 31556952000;

            this.xAxis[0].setExtremes(start, end);
          },
        };

        getAnnotationData(
          company_id,
          location.pathname.replace("/", ""),
          person
        )
          .then((annotationres) => {
            const labels = [];

            annotationres.forEach((item) => {
              const {
                date,
                date_changed,
                name,
                person,
                share_change,
                holder,
                current_ratio,
                previous_ratio,
                reason,
                url,
              } = item;

              try {
                labels.push({
                  point: {
                    xAxis: 0,
                    yAxis: 0,
                    // x: Date.parse(date),
                    x: Date.parse(date_changed ? date_changed : date), // 매수변동일자
                    y: res.find(
                      (item) =>
                        item.x ===
                        Date.parse(date_changed ? date_changed : date)
                    ).close,
                  },
                  text:
                    location.pathname.replace("/", "") === "massshares"
                      ? `${holder} ${current_ratio}% ${
                          current_ratio - previous_ratio > 0
                            ? "<span style='color: crimson'>⬆︎</span>"
                            : current_ratio === previous_ratio
                            ? ""
                            : "<span style='color: deepskyblue'>⬇︎</span>"
                        }`
                      : `${
                          "<p style='color:orange;font-size:0.6rem;'>" +
                          reason +
                          "</p>"
                        } ${person} ${
                          share_change > 0
                            ? "<span style='color: crimson'>⬆︎</span>"
                            : share_change < 0
                            ? "<span style='color: deepskyblue'>⬇︎</span>"
                            : reason.includes("+") && reason.includes("-")
                            ? ""
                            : reason.includes("+")
                            ? "<span style='color: crimson'>⬆︎</span>"
                            : "<span style='color: deepskyblue'>⬇︎</span>"
                        }`,

                  style: {
                    fontSize: "0.8rem",
                    color: "white",
                  },
                  useHTML: true,
                });
              } catch {}
              // title = { ...title, text: "//" };
            });
            const annotations = [
              {
                draggable: "",
                labels: labels,
                labelOptions: {
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  fontWeight: "bold",
                },
                events: {
                  click: function (e) {
                    console.log(e);
                  },
                },
              },
            ];

            getIncomeStatementData(company_id).then((incomedata) => {
              setIncomes(incomedata[1]);

              // 실적 라인 series 생성
              const incomeSeries = [];

              Object.keys(incomedata[0]).forEach((account) => {
                incomeSeries.push({
                  type: "line",
                  data: incomedata[0][account],
                  events: {
                    click: function (e) {
                      console.log(e);
                    },
                  },
                  color:
                    account === "revenue"
                      ? "#058DC7"
                      : account === "operating_profit"
                      ? "#50B432"
                      : "#ED561B",
                  name:
                    account === "revenue"
                      ? "매출"
                      : account === "operating_profit"
                      ? "영업이익"
                      : "순이익",
                  yAxis: 1,
                  step: "right",
                  // visible: account === "revenue" ? true : false,
                  visible: false,
                });
              });
              incomeSeries.sort((b, a) => {
                return a.name === "매출"
                  ? 1
                  : a.name === "영업이익" && b.name !== "매출"
                  ? 1
                  : -1;
              });

              series.push(...incomeSeries);

              // PER 가이드 라인 series 생성

              const netProfitDataList = incomedata[0].net_profit; // 순이익 리스트
              const cumulSumList = netProfitDataList // 직전 4분기 net profit 합계
                ?.slice(3)
                ?.map((netprofit, idx) => {
                  const sum = netProfitDataList
                    .slice(idx, idx + 4)
                    .reduce((a, b) => a + b.y, 0);
                  return {
                    x: netprofit.x,
                    cumulativeIncome: parseFloat(sum).toFixed(),
                  };
                })
                .filter((item) => item.cumulativeIncome > 0);
              console.log(cumulSumList);
              if (
                cumulSumList !== undefined &&
                cumulSumList[cumulSumList?.length - 1]?.cumulativeIncome > 0
              ) {
                const currentPER = parseFloat(
                  companyInfo.marketcap /
                    cumulSumList[cumulSumList?.length - 1].cumulativeIncome
                ).toFixed(); // 가장 최근 누적 ni 를 기준으로 per 계산

                const pers = [
                  currentPER * 0.5,
                  currentPER * 0.7,
                  // currentPER * 0.9,
                  currentPER,
                  // currentPER * 1.15,
                  currentPER * 1.3,
                  currentPER * 1.5,
                ]; // per data array
                console.log(cumulSumList, currentPER);

                const perSeries = [
                  {
                    name: "PER",
                    id: "PER",
                    data: [],
                    visible: false,
                  },
                ];

                pers.forEach((per, idx) => {
                  perSeries.push({
                    type: "line",
                    dashStyle: "ShortDash",
                    name: `PER${parseFloat(per)?.toFixed(1)}`,
                    data: cumulSumList.map((item) => {
                      return {
                        x: item.x,
                        y:
                          parseFloat(
                            (item.cumulativeIncome * 100000000) /
                              companyInfo.listedshares
                          ).toFixed() * per,
                      };
                    }),
                    opacity: 0.4,
                    linkedTo: "PER",
                    yAxis: 0,
                    step: "right",
                    enableMouseTracking: false,
                    zIndex: 0,
                    dataLabels: {
                      enabled: true,
                      // crop: false,
                      overflow: "allow",
                      align: "left",
                      verticalAlign: "middle",
                      style: {
                        textOutline: 0,
                        opacity: 0.8,
                      },
                      formatter: function () {
                        if (
                          this.point.x.toString().slice(0, 4) ===
                          this.series.xData[this.series.xData.length - 1]
                            .toString()
                            .slice(0, 4)
                        ) {
                          return (
                            '<p style="color:' +
                            this.series.color +
                            '">' +
                            this.series.name +
                            "</p>"
                          );
                        } else {
                          return;
                        }
                      },
                    },
                  });
                });

                series.push(...perSeries);
              }

              const scrollbar = {
                // 스크롤바
                height: 1,
              };

              setOptions((prev) => {
                const temp = {
                  ...prev,

                  title,
                  series,
                  scrollbar,
                  annotations,
                };

                return temp;
              });
            });
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setOptions((prev) => {
              const temp = {
                ...prev,
                chart: {
                  ...prev.chart,
                  events,
                },
              };

              return temp;
            });

            setIsLoading(false);
          });
      });
    };
    start();
  }, [company_id, person]);

  const columns = useMemo(
    () => [
      {
        accessor: "date",
        Header: () => <span className="text-xs ">분기</span>,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => <span className="text-xs "> {value}</span>,
      },
      {
        accessor: "revenue",
        Header: () => <span className="text-xs ">매출</span>,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => <span className="text-xs "> {value}</span>,
      },
      {
        accessor: "operating_profit",
        Header: () => <span className="text-xs ">OP</span>,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <span
            className={`text-xs ${
              value > 0 ? "text-text-plus" : "text-text-minus"
            }`}
          >
            {value}
          </span>
        ),
      },
      {
        accessor: "net_profit",
        Header: () => <span className="text-xs ">NI</span>,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <span
            className={`text-xs ${
              value > 0 ? "text-text-plus" : "text-text-minus"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],

    []
  );

  return (
    <div className="flex">
      {isLoading ? (
        <div
          className={`w-full h-[400px] text-center bg-slate-500 text-bg p-5 rounded my-2 text-xlg font-bold flex items-center justify-center 
        
        `}
        >
          ....loading....
        </div>
      ) : (
        <>
          <div className={`w-full`}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              constructorType={"stockChart"}
            />
          </div>
          <div className="hidden xl:inline lg:w-[300px]">
            {incomes?.length > 0 ? (
              <TableContent
                isLoading={isLoading}
                columns={columns}
                data={incomes}
                itemPerPage={14}
                enableCompactControlPanel={true}
              />
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}
