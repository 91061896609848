import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TableContent from "../../TableContent";

export default function Sjgroup() {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const handleDuplicate = (dt) => {
    // 동일한 데이터 제거
    let temp = {};
    const reversed = (arr, asc) =>
      arr.sort((a, b) => {
        return asc
          ? Date.parse(a.date) - Date.parse(b.date)
          : Date.parse(b.date) - Date.parse(a.date);
      });

    const reversedData = reversed(dt, true).filter((item) => {
      const { date, ...prep } = item;
      if (JSON.stringify(temp) === JSON.stringify(prep)) {
        return false;
      } else {
        temp = { ...prep };
        return true;
      }
    });

    return reversed(reversedData, false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/sjgroup");

      setData(handleDuplicate(res.data));
      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "일자",
        accessor: "date",
        disableFilters: true,
      },
      {
        Header: "팬암",
        accessor: "panam",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "LCDC",
        accessor: "lcdc",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "캉골키즈",
        accessor: "kangolkids",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "캉골",
        columns: [
          {
            accessor: "kangol_total",
            Header: "합계",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "kangol_department",
            Header: "백화점",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "kangol_outlet",
            Header: "아울렛",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "kangol_roadshop",
            Header: "로드샵",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "kangol_dutyfree",
            Header: "면세점",
            disableFilters: true,
            disableSortBy: true,
          },
        ],
      },
      {
        Header: "헬렌카민스키",
        columns: [
          {
            accessor: "helen_total",
            Header: "합계",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "helen_department",
            Header: "백화점",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "helen_outlet",
            Header: "아울렛",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "helen_dutyfree",
            Header: "면세점",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "helen_online",
            Header: "온라인스토어",
            disableFilters: true,
            disableSortBy: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      disableControlPanel={true}
    />
  );
}
