import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TableContent from "../TableContent";

export default function Trademark() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/trademark");
      setData(res.data);
      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: "app_date",
        Header: "출원일",
        disableFilters: true,
      },
      {
        accessor: "applicant",
        Header: "회사명",

        disableSortBy: true,
      },
      {
        accessor: "image",
        Header: "이미지",
        Cell: ({ value }) => {
          return (
            <div>
              <img
                src={`http://www.stockinu.com/${value}`}
                className="max-w-[80px] rounded-xl border-2 border-slate-400"
              />
            </div>
          );
        },
        disableSortBy: true,
        disableFilters: true,
      },

      { accessor: "products", Header: "지정상품", disableSortBy: true },
    ],
    []
  );

  return <TableContent isLoading={isLoading} columns={columns} data={data} />;
}
