import axios from "axios";
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

async function getData() {
  const data = {};
  const url = "http://stockinu.com:4000/api/golfzons/monthly";
  const bulkDataFetched = await axios.get(url).then((res) => res.data);
  bulkDataFetched.forEach((item, idx) => {
    const date = item["date"];
    for (const key in item) {
      if (key in data) {
        data[key]["data"].push({
          date: date,
          x: Date.parse(date.slice(0, 7)),
          y:
            bulkDataFetched[idx - 1][key] === 0
              ? 0
              : item[key] - bulkDataFetched[idx - 1][key],
        });
      } else {
        data[key] = {};
        data[key]["name"] = key;
        data[key]["data"] = [];
      }
    }
  });

  return data;
}

export default function GolfzonChart() {
  const menus = [
    ["frs_shops", "가맹점"],
    ["non_frs_shops", "비가맹점"],
    ["total", "총시스템"],
    ["twovision", "투비젼"],
    ["twovisionplus", "투비젼플러스"],
    ["gdr", "gdr"],
    ["gdr_shops", "gdr비가맹"],
    // ["japan_shops", "일본"],
    // ["china_shops", "중국"],
    // ["kakao_global", "카카오(해외)"],
    // ["kakao_indoor", "카카오"],
  ];
  const base_options = {
    // 기초스타일
    title: {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: ``,
    },
    chart: {
      // 차트 스타일 설정
      backgroundColor: "transparent",
      // plotBorderWidth: 1,
      zoomType: "x",
    },
    colors: [
      "#058DC7",
      "#50B432",
      "#ED561B",
      "#DDDF00",
      "#24CBE5",
      "#64E572",
      "#FF9655",
      "#FFF263",
      "#6AF9C4",
    ],
    accessibility: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    yAxis: [
      {
        title: {
          text: "개",
        },
        gridLineColor: "transparent",
        opposite: true,
      },
      {
        title: {
          text: "개",
        },
        gridLineColor: "transparent",
      },
    ],

    tooltip: {
      borderRadius: 20,
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "#33537970",
      },
    },
  };
  const [options, setOptions] = useState(base_options);
  useEffect(() => {
    getData().then((res) => {
      const series = [];
      menus.forEach((menu, idx) => {
        const item = res[menu[0]];
        series.push({
          type:
            idx === 0 ||
            idx === 1 ||
            idx === 7 ||
            idx === 8 ||
            idx === 9 ||
            idx === 10
              ? "column"
              : "line",
          data: item.data,
          name: `${menu[1]}${
            idx === 0 ||
            idx === 1 ||
            idx === 7 ||
            idx === 8 ||
            idx === 9 ||
            idx === 10
              ? "(우)"
              : "(좌)"
          }`,
          marker: {
            radius: 3,
          },
          // lineWidth: 3,
          yAxis:
            idx === 0 ||
            idx === 1 ||
            idx === 7 ||
            idx === 8 ||
            idx === 9 ||
            idx === 10
              ? 0
              : 1,
          visible: idx === 0 || idx === 1 || idx === 7 || idx === 8,
        });
      });

      const title = {
        style: {
          color: "white",
          fontWeight: "bold",
        },
      };

      setOptions((prev) => {
        const temp = {
          ...prev,
          series,
          title,
        };
        return temp;
      });
    });
  }, []);

  return (
    <div className="w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType={"chart"}
      />
    </div>
  );
}
