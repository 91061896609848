import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./pages/Root";

import Shares from "./pages/shares/Shares";
import MassShares from "./pages/shares/MassShares";
import FreeToSell from "./pages/shares/FreeToSell";
import Fstatement from "./pages/fstatement/Fstatement";
import Employees from "./pages/employ/Employees";
import Consensus from "./pages/earnings/Consensus";
import Updown from "./pages/earnings/Updown";
import Transaction from "./pages/stocks/Transaction";
import Nettrade from "./pages/stocks/Nettrade";
import Backlogs from "./pages/etcs/Backlogs";
import Trademark from "./pages/etcs/Trademark";
import Stores from "./pages/etcs/Stores";
import Golfzon from "./pages/etcs/stores/Golfzon";
import Jeisys from "./pages/etcs/stores/Jeisys";
import Kcar from "./pages/etcs/stores/Kcar";
import Afreecatv from "./pages/etcs/stores/Afreecatv";
import Sjgroup from "./pages/etcs/stores/Sjgroup";
import Gamseong from "./pages/etcs/stores/Gamseong";
import Daesung from "./pages/etcs/stores/Daesung";

import Ilooda from "./pages/etcs/stores/Ilooda";
import Oliveyoung from "./pages/etcs/stores/Oliveyoung";

import Kyochon from "./pages/etcs/stores/Kyochon";

import Export from "./pages/etcs/Export";
import Reviews from "./pages/etcs/Reviews";
import ErrorPage from "./pages/ErrorPage";
import Main from "./pages/main/Main";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "/",
        element: <Main />,
      },
      {
        path: "shares/",
        element: <Shares />,
      },
      {
        path: "massshares/",
        element: <MassShares />,
      },
      {
        path: "freetosell/",
        element: <FreeToSell />,
      },

      {
        path: "employees/",
        element: <Employees />,
      },
      {
        path: "updown/",
        element: <Updown />,
      },
      {
        path: "transaction/",
        element: <Transaction />,
      },
      {
        path: "nettrade/",
        element: <Nettrade />,
      },
      {
        path: "consensus/",
        element: <Consensus />,
      },
      {
        path: "backlogs/",
        element: <Backlogs />,
      },
      {
        path: "trademark/",
        element: <Trademark />,
      },
      {
        path: "stores/",
        element: <Stores />,
        children: [
          {
            index: true,
            path: "stores/",
            element: <Golfzon />,
          },
          {
            path: "golfzon",
            element: <Golfzon />,
          },
          {
            path: "jeisys",
            element: <Jeisys />,
          },
          {
            path: "kcar",
            element: <Kcar />,
          },
          {
            path: "afreecatv",
            element: <Afreecatv />,
          },
          {
            path: "sjgroup",
            element: <Sjgroup />,
          },
          {
            path: "gamseong",
            element: <Gamseong />,
          },
          {
            path: "daesung",
            element: <Daesung />,
          },
          {
            path: "ilooda",
            element: <Ilooda />,
          },
          {
            path: "kyochon",
            element: <Kyochon />,
          },
          {
            path: "oliveyoung",
            element: <Oliveyoung />,
          },
        ],
      },
      {
        path: "export/",
        element: <Export />,
      },
      {
        path: "reviews/",
        element: <Reviews />,
      },
      {
        path: "fstatement/",
        element: <Fstatement />,
      },
      // {
      //   path: "inventory/",
      //   element: <Fstatement account={"inventory"} />,
      // },
      // {
      //   path: "advanced/",
      //   element: <Fstatement account={"advanced"} />,
      // },
      // {
      //   path: "long_advanced/",
      //   element: <Fstatement account={"long_advanced"} />,
      // },
      // {
      //   path: "unearned/",
      //   element: <Fstatement account={"unearned"} />,
      // },
      // {
      //   path: "long_unearned/",
      //   element: <Fstatement account={"long_unearned"} />,
      // },
      // {
      //   path: "contract/",
      //   element: <Fstatement account={"contract"} />,
      // },
      // {
      //   path: "long_contract/",
      //   element: <Fstatement account={"long_contract"} />,
      // },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
