import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TableContent from "../../TableContent";

export default function Jeisys() {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const handleDuplicate = (dt) => {
    // 동일한 데이터 제거
    let temp = {};
    const reversed = (arr, asc) =>
      arr.sort((a, b) => {
        return asc
          ? Date.parse(a.date) - Date.parse(b.date)
          : Date.parse(b.date) - Date.parse(a.date);
      });

    const reversedData = reversed(dt, true).filter((item) => {
      const { date, ...prep } = item;
      if (JSON.stringify(temp) === JSON.stringify(prep)) {
        return false;
      } else {
        temp = { ...prep };
        return true;
      }
    });

    return reversed(reversedData, false);
  };
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/jeisys");
      setData(handleDuplicate(res.data));
      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "일자",
        accessor: "date",
        disableFilters: true,
        Cell: ({ value }) => {
          return value;
          return value.slice(2, 10);
        },
      },
      {
        Header: "포텐자",
        columns: [
          {
            accessor: "potenza_total",
            Header: "합계",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_seoul",
            Header: "서울",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_gyeongin",
            Header: "경인",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_chungcheong",
            Header: "충청",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_jeolla",
            Header: "전라",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_gyeongsang",
            Header: "경상",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_gangwon",
            Header: "강원",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "potenza_jeju",
            Header: "제주",
            disableFilters: true,
            disableSortBy: true,
          },
        ],
      },
      {
        Header: "리니어펌",
        columns: [
          {
            accessor: "lf_total",
            Header: "합계",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_seoul",
            Header: "서울",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_gyeongin",
            Header: "경인",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_chungcheong",
            Header: "충청",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_jeolla",
            Header: "전라",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_gyeongsang",
            Header: "경상",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_gangwon",
            Header: "강원",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lf_jeju",
            Header: "제주",
            disableFilters: true,
            disableSortBy: true,
          },
        ],
      },
      {
        Header: "리니어Z",
        columns: [
          {
            accessor: "lz_total",
            Header: "합계",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_seoul",
            Header: "서울",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_gyeongi",
            Header: "경기",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_incheon",
            Header: "인천",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_chungcheong",
            Header: "충청",
            disableFilters: true,
          },
          {
            accessor: "lz_jeolla",
            Header: "전라",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_gyeongsang",
            Header: "경상",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_gangwon",
            Header: "강원",
            disableFilters: true,
            disableSortBy: true,
          },
          {
            accessor: "lz_jeju",
            Header: "제주",
            disableFilters: true,
            disableSortBy: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      disableControlPanel={true}
    />
  );
}
