import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";
import { IconContext } from "react-icons/lib";

export default function SearchInput({ handleKeyword, datalist }) {
  const [text, setText] = useState("");
  const [matched, setMatched] = useState([]);
  const [selectedByKey, setSelectedByKey] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text === "") {
      return;
    }

    const company = datalist.find((item) => {
      return item.name.toLowerCase() === text.toLowerCase();
    });
    if (company) {
      handleKeyword({ company_id: company.id, name: company.name });
    } else {
      alert("Not Found");
    }

    handleRemove();

    // handleKeyword(code, name); // prop으로 부모한테 받아온 함수로 코드, 이름 넘김
  };
  const handleChange = (e) => {
    setSelectedByKey(undefined);
    const amendedText = e.target.value.replace(" ", "");
    setText(amendedText);
    const searched = datalist.filter((item) => {
      return amendedText !== ""
        ? item.name.toLowerCase().includes(amendedText.toLowerCase())
        : null;
    });

    setMatched(searched ?? []);
  };

  const handleKeyDown = (e) => {
    // 인풋박스에서 키를 눌렀을때 핸들러, 즉, 위아래 키를 통한 텍스트 선택

    if (e.nativeEvent.isComposing) {
      return;
    }
    switch (e.key) {
      case "ArrowDown":
        setSelectedByKey((prev) => {
          const selectedIdx = !isNaN(prev)
            ? prev + 1 < matched?.length
              ? prev + 1
              : 0
            : 0;

          e.value = matched[selectedIdx]?.name;
          return selectedIdx;
        });
        break;
      case "ArrowUp":
        setSelectedByKey((prev) => {
          const selectedIdx = !isNaN(prev)
            ? prev - 1 < 0
              ? matched?.length - 1
              : prev - 1
            : 0;
          e.value = matched[selectedIdx]?.name;

          return selectedIdx;
        });
        break;
      case "Enter":
        setText(matched[selectedByKey]?.name);
        break;
      case "Escape":
        handleRemove();
        break;
      default:
        break;
    }
  };
  const handleRemove = (e) => {
    setText("");
    setMatched([]);
  };
  return (
    <div className="relative">
      <form
        // onChange={handleChange}
        onSubmit={handleSubmit}
        onKeyDown={handleKeyDown}
      >
        <input
          onChange={handleChange}
          type="text"
          className="bg-transparent border-none bg-slate-600 w-min outline-none pl-2 rounded inline text-text "
          placeholder="관심회사를 입력하세요..."
          value={text}
        />
        {text !== "" ? (
          <IconContext.Provider
            value={{
              attr: {
                onClick: (e) => {
                  handleRemove(e);
                },
              },
              style: {
                display: "inline",
                position: "relative",
                left: "-1.5rem",
                bottom: "0.1rem",
                color: "white",
                fontSize: "1.2rem",
              },
            }}
          >
            <TiDelete />
          </IconContext.Provider>
        ) : (
          ""
        )}
        <div className=" bg-slate-100 rounded mt-1 text-bg">
          <ul>
            {matched.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={`hover:bg-slate-400 hover:text-bg px-2 text-sm py-[2px] ${
                    // 업다운키시 하일라이트
                    idx === selectedByKey ? "bg-orange-200" : ""
                  }`}
                  onClick={(e) => {
                    handleRemove();
                    handleKeyword({
                      company_id: matched[idx].id,
                      name: matched[idx].name,
                    }); // 검색된 것 중에 클릭하면 데이터 바로 넘겨줌
                  }}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
      </form>
    </div>
  );
}
