import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TransactionChart from "../../components/TransactionChart";
import TableContent from "../TableContent";

export default function Nettrade() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get(
        "http://stockinu.com:4000/api/transaction/recent/trader/pef/days/5/buysell/sell"
      );

      setData(res.data);
      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ code, name }) => {
    setChartInfo({ code, name });
  };

  const columns = useMemo(
    () => [{ accessor: "name", Header: "회사명", disableSortBy: true }],
    []
  );

  return (
    <>
      <select name="" id="">
        <option value="">---거래원---</option>
        <option value="1">개인</option>
        <option value="2">외국인</option>
        <option value="3">기관</option>
      </select>
      <select name="" id="">
        <option value="">---최근?일----</option>
        <option value="1">최근5일</option>
        <option value="2">최근7일</option>
        <option value="3">최근10일</option>
      </select>
      <select name="" id="">
        <option value="">---매수매도---</option>
        <option value="1">연속매수</option>
        <option value="2">연속매도</option>
      </select>

      <TableContent
        isLoading={isLoading}
        columns={columns}
        data={data}
        handleChartInfo={handleChartInfo}
      >
        {chartInfo ? (
          <div className={`${!chartInfo && "hidden"}  h-full`}>
            <TransactionChart chartInfo={chartInfo} />
          </div>
        ) : (
          <></>
        )}
      </TableContent>
    </>
  );
}
