import axios from "axios";
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// "id": 1,
// "date": "2022-04-01",
// "numberOfBJ": 13002,
// "totalBalloons": 490354833,
// "totalViewers": 311899243,
// "totalRunningTime": 4518368830

async function getChartData(code) {
  const newdata = { bj: [], balloons: [], viewer: [], runningTime: [] };

  await axios
    .get(`http://stockinu.com:4000/api/afreecatvbj`)
    .then((res) => {
      res.data.forEach((item) => {
        newdata.bj.push({
          x: Date.parse(item.date), // milliseconds 단위
          y: item.numberOfBJ,
        });
        newdata.balloons.push({
          x: Date.parse(item.date), // milliseconds 단위
          y: item.totalBalloons,
        });
        newdata.viewer.push({
          x: Date.parse(item.date), // milliseconds 단위
          y: item.totalViewers,
        });
        newdata.runningTime.push({
          x: Date.parse(item.date), // milliseconds 단위
          y: item.totalRunningTime,
        });
      });
    })
    .finally(() => {});

  return newdata;
}

export default function Afreecatv() {
  const base_options = {
    chart: {
      // 차트 스타일 설정
      backgroundColor: "transparent",
      // plotBorderWidth: 1,
      type: "column",
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "명",
      },
      gridLineColor: "transparent",
    },

    tooltip: {
      borderRadius: 20,
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "#33537970",
      },
    },
    plotOptions: {
      series: {
        pointWidth: 1,
      },
    },
  };

  const [options, setOptions] = useState(base_options);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getChartData()
      .then((res) => {
        // chart data 셋업

        const series = [
          {
            // type: "column",
            data: res.bj,
            name: "활동bj수",
            color: "white",
          },
          {
            // type: "column",
            data: res.viewer,
            name: "시청자수",
          },
          {
            // type: "column",
            data: res.runningTime,
            name: "시청시간",
          },
          {
            type: "line",
            data: res.balloons,
            name: "별풍선수",
            // color: "",
            // marker: {
            //   radius: 3,
            //   lineColor: "red",
            // },
            // lineWidth: 3,
          },
        ];

        const title = {
          style: {
            color: "white",
            fontWeight: "bold",
          },
          text: undefined,
        };

        setOptions((prev) => {
          const temp = {
            ...prev,
            series,
            title,
          };
          return temp;
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="w-full">
      <div
        className={`w-full text-center bg-slate-500 text-bg p-5 rounded my-2 text-xlg font-bold ${
          !isLoading && "hidden"
        }`}
      >
        ....loading....
      </div>
      <div className={`${isLoading && "hidden"}`}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"chart"}
        />
      </div>
    </div>
  );
}
