import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import BacklogsChart from "../../components/BacklogsChart";
import TableContent from "../TableContent";

export default function Backlogs() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState(null);
  const [dateInfo, setDateInfo] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get(
        "http://stockinu.com:4000/api/backlog/recent"
      );

      setDateInfo(res.data[0][0]);

      setData(
        res.data[1]
          .filter((item) => {
            return item.previous || item.recent;
          })
          .map((item) => {
            return {
              ...item,
              change: item.previous
                ? ((item.recent / item.previous - 1) * 100).toFixed(1)
                : "",
            };
          })
      );
      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ company_id, name }) => {
    setChartInfo({ company_id, name });
  };

  const defaultNumberSort = useMemo(() => (rowA, rowB, columnId) => {
    return Number(rowA.values[columnId]) >= Number(rowB.values[columnId])
      ? 1
      : -1;
  });

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "회사명",
        // disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "recent",
        Header: dateInfo?.recent.slice(0, 7) ?? "",
        disableFilters: true,
        Cell: ({ value }) => {
          if (value) {
            return (value / 100000000)
              .toFixed()
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        },
      },
      {
        accessor: "previous",
        Header: dateInfo?.previous.slice(0, 7) ?? "",
        disableFilters: true,
        Cell: ({ value }) => {
          if (value) {
            return (value / 100000000)
              .toFixed()
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        },
      },
      {
        accessor: "change",
        Header: "변동율(%)",
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value}
            </span>
          );
        },
        type: "change",
        sortType: defaultNumberSort,
      },
      // { accessor: "url", Header: "url", type: "url" },
    ],
    [dateInfo]
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      handleChartInfo={handleChartInfo}
    >
      {chartInfo?.company_id ? (
        <BacklogsChart
          company_id={chartInfo?.company_id}
          name={chartInfo?.name}
        />
      ) : (
        <></>
      )}
    </TableContent>
  );
}
