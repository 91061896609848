import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import StockChart from "../../components/StockChart";
import TableContent from "../TableContent";
import { BiShow, BiHide } from "react-icons/bi";
import { FaSpinner } from "react-icons/fa";
import styles from "./Shares.module.css";

export default function Shares() {
  const [isLoading, setIsLoading] = useState(false);
  const [isBuyListLoading, setBuyListIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState(null);
  const [buyListPersonInfo, setBuyListPersonInfo] = useState(null);
  const [buylistData, setBuyListData] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [monitoringMonth, setMonitoringMonth] = useState(3);
  const [showBuyListTable, setShowBuyListTable] = useState(true);
  const [dateRange, setDateRange] = useState(undefined);
  const [showPersonOnly, setShowPersonOnly] = useState(false);

  const sliceDateToSix = (date) => date?.slice(2, 10);

  // **************** date filter *******************************
  const DateRangeColumnFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) => {
    const [min, max] = useMemo(() => {
      let max = filterValue[1]
        ? filterValue[1]
        : preFilteredRows[0]?.values[id];
      let min = filterValue[0]
        ? filterValue[0]
        : preFilteredRows[preFilteredRows?.length - 1]?.values[id];

      return [min, max];
    }, [filterValue]);

    return (
      <div className="flex items-center gap-2">
        <div className="flex flex-col gap-0.5">
          <input
            className={
              "bg-bg text-text font-normal text-xs rounded px-1 " +
              styles.inputcalendar
            }
            value={filterValue[0] ? filterValue[0] : min}
            type="date"
            min={preFilteredRows[preFilteredRows?.length - 1]?.values[id]}
            max={
              preFilteredRows[0]?.values[id] > max
                ? max
                : preFilteredRows[0]?.values[id]
            }
            onChange={(e) => {
              const val = e.target.value;
              setDateRange([val, max]);
              setFilter([val, max]);
            }}
            placeholder={`Min (${min})`}
          />

          <input
            className={
              "bg-bg text-text font-normal text-xs rounded px-1 " +
              styles.inputcalendar
            }
            value={filterValue[1] ? filterValue[1] : max}
            type="date"
            min={
              preFilteredRows[preFilteredRows?.length - 1]?.values[id] < min
                ? min
                : preFilteredRows[preFilteredRows?.length - 1]?.values[id]
            }
            max={preFilteredRows[0]?.values[id]}
            onChange={(e) => {
              const val = e.target.value;
              setDateRange([min, val]);
              setFilter([min, val]);
            }}
            placeholder={`Max (${max})`}
          />
        </div>
      </div>
    );
  };

  const betweenDate = useMemo(() => (rows, id, filterValue) => {
    return rows.filter(
      (row) =>
        row.values[id] >= filterValue[0] && row.values[id] <= filterValue[1]
    );
  });

  // ****************************************************************

  const getData = async () => {
    const res = await axios.get("http://stockinu.com:4000/api/shares/all");
    return res.data;
  };

  const getDataForBuyListData = async (company_id, person) => {
    setBuyListIsLoading(true);
    const res = await axios.get(
      `http://stockinu.com:4000/api/shares/company_id/${company_id}/person/${person}/month/${monitoringMonth}`
    );

    setBuyListData(() => {
      const dataWillSave = res.data.map((item) => {
        return {
          id: item.id,
          info: item.company_id + item.person,
          code: item.acquired_stockprice?.code,
          date: item.date,
          date_changed: item.date_changed,
          name: item.name,
          company_id: item.company_id,
          person: item.person,
          person_rank: item.person_rank,
          reason: item.reason,
          url: item.url,
          init_date: item.acquired_stockprice?.date,
          init_price: item.acquired_stockprice?.close,
          max_date: item.highest_stockprice?.date,
          max_price: item.highest_stockprice?.close,
          uprate: (
            (item.highest_stockprice?.close / item.acquired_stockprice?.close -
              1) *
            100
          ).toFixed(0),
          min_date: item.lowest_stockprice?.date,
          min_price: item.lowest_stockprice?.close,
          downrate: (
            (item.lowest_stockprice?.close / item.acquired_stockprice?.close -
              1) *
            100
          ).toFixed(0),

          max_date_2x: item.highest_stockprice_2x?.date,
          max_price_2x: item.highest_stockprice_2x?.close,
          uprate_2x: (
            (item.highest_stockprice_2x?.close /
              item.acquired_stockprice?.close -
              1) *
            100
          ).toFixed(0),
          min_date_2x: item.lowest_stockprice_2x?.date,
          min_price_2x: item.lowest_stockprice_2x?.close,
          downrate_2x: (
            (item.lowest_stockprice_2x?.close /
              item.acquired_stockprice?.close -
              1) *
            100
          ).toFixed(0),
        };
      });
      return dataWillSave;
    });
    setBuyListIsLoading(false);

    return res.data;
  };

  const handleChange = (e) => {
    setMonitoringMonth(e.target.value);
  };
  const handleCheckBox = (e) => {
    setShowPersonOnly((prev) => !prev);
  };

  useEffect(() => {
    setFilteredData(
      data.filter((item) => (showPersonOnly ? item.person_rank !== "-" : true))
    );
  }, [showPersonOnly, data]);

  useEffect(() => {
    if (!chartInfo) {
      return;
    }
    const { company_id, person } = chartInfo;

    // const person = buylistData?.person;
    getDataForBuyListData(company_id, person);
  }, [chartInfo, monitoringMonth, buyListPersonInfo]);

  useEffect(() => {
    setIsLoading(true);
    getData()
      .then((res) => setData(res))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChartInfo = ({
    company_id,
    name,
    row: {
      values: { person },
    },
  }) => {
    setChartInfo({ company_id, name, person });
    setBuyListPersonInfo(null);
    getDataForBuyListData(company_id, person);
    setShowBuyListTable(true);
  };

  const handleBuyListChartInfo = ({
    company_id,
    name,
    row: {
      original: { person },
    },
  }) => {
    if (
      chartInfo?.company_id === company_id &&
      buyListPersonInfo?.person === person
    ) {
      return;
    }

    setChartInfo({ company_id, name, person });
    setBuyListPersonInfo({ person });
    getDataForBuyListData(company_id, person);
  };

  const defaultNumberSort = useMemo(
    () => (rowA, rowB, columnId) =>
      Number(rowA.values[columnId]) >= Number(rowB.values[columnId]) ? 1 : -1
  );

  const history_columns = useMemo(
    () => [
      {
        accessor: "date",
        Header: "공시일",
        disableFilters: true,
        Cell: ({ value }) => (
          <span className="text-xs md:text-sm">{sliceDateToSix(value)}</span>
        ),
      },
      {
        accessor: "reason",
        Header: "내용",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: () => (
          <span className="text-center flex justify-center rounded bg-slate-400 text-bg w-full">
            매수(변동)시점
          </span>
        ),
        id: "init_stock_price",
        columns: [
          {
            Header: " ",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row }) => {
              return (
                <>
                  <>
                    <span className="text-xs md:text-sm">
                      {row.original.init_price
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </span>
                    <span className="text-xs block md:inline md:text-xs">
                      ({sliceDateToSix(row.original.init_date)})
                    </span>
                  </>
                </>
              );
            },
          },
        ],
      },

      {
        Header: () => (
          <span className="text-center flex justify-center rounded bg-slate-400 text-bg  w-full">
            {monitoringMonth} 개월 주가 변동폭
          </span>
        ),
        id: "6months_stock_price",

        columns: [
          {
            Header: "최대상승",
            disableFilters: true,
            disableSortBy: true,
            id: "max_price",
            Cell: ({ row }) => {
              return (
                <>
                  <span className="text-xs md:text-sm">
                    {row.original.max_price
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                  <span className="text-xs block md:inline md:text-xs">
                    ({sliceDateToSix(row.original.max_date)})
                  </span>
                </>
              );
            },
          },
          {
            accessor: "uprate",
            Header: "최대상승폭",
            id: "uprate",
            disableFilters: true,
            Cell: ({ value }) => (
              <span
                className={`${
                  value >= 50 ? "text-text-plus" : "text-slate-300"
                } text-right flex justify-center`}
              >
                {value + "%"}
              </span>
            ),
          },
          {
            Header: "최대하락",
            disableFilters: true,
            disableSortBy: true,
            id: "min_price",
            Cell: ({ row }) => {
              return (
                <>
                  <span className="text-xs md:text-sm">
                    {row.original.min_price
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                  <span className="text-xs block md:inline md:text-xs">
                    ({sliceDateToSix(row.original.min_date)})
                  </span>
                </>
              );
            },
          },
          {
            accessor: "downrate",
            Header: "최대하락폭",
            id: "downrate",
            disableFilters: true,
            Cell: ({ value }) => (
              <span
                className={`${
                  value <= -30 ? "text-text-minus" : "text-slate-300"
                } text-right flex justify-center`}
              >
                {value + "%"}
              </span>
            ),
          },
        ],
      },
    ],
    [monitoringMonth]
  );

  const columns = useMemo(
    () => [
      {
        accessor: "date",
        Header: "공시일",
        // disableFilters: true,
        // disableSortBy: true,
        Filter: DateRangeColumnFilter,
        filter: betweenDate,
      },
      {
        accessor: "date_changed",
        Header: "변동일",
        disableFilters: true,
        type: "date",
      },
      { accessor: "name", Header: "회사명", disableSortBy: true },
      { accessor: "person", Header: "보고자", disableSortBy: true },
      { accessor: "person_rank", Header: "직책", disableSortBy: true },
      {
        accessor: "share_change",
        Header: "변동지분율",
        disableFilters: true,
        Cell: ({ value }) => {
          return value === 0 ? (
            "-"
          ) : (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value.toFixed(2)}%
            </span>
          );
        },
        type: "change",
        sortType: defaultNumberSort,
      },
      // { accessor: "url", Header: "url", type: "url" },
      { accessor: "reason", Header: "변동사유", disableSortBy: true },
    ],
    []
  );

  return (
    <>
      {data ? (
        <TableContent
          isLoading={isLoading}
          columns={columns}
          data={filteredData}
          handleChartInfo={isBuyListLoading ? undefined : handleChartInfo}
        >
          <>
            {chartInfo?.company_id ? (
              <StockChart
                company_id={chartInfo?.company_id}
                name={chartInfo?.name}
                person={buyListPersonInfo?.person}
              />
            ) : (
              <></>
            )}
          </>
          <div className="rounded bg-slate-700 mb-3">
            {isBuyListLoading && !buylistData ? (
              <div className="text-2xl flex justify-center">
                <FaSpinner className="self-center animate-spin mx-2 text-sm" />
                <div className="self-center">Loading....</div>
              </div>
            ) : buylistData?.length > 0 ? (
              <div className="px-0 md:px-2 mb-1 text-xs ">
                <div className="flex justify-evenly items-center">
                  <span className="text-sm font-black rounded  p-1 ">
                    {!isBuyListLoading ? (
                      <>
                        {buylistData[0].name} {buylistData[0].person + " "}
                        {buylistData[0].person_rank}
                      </>
                    ) : (
                      "Loading..."
                    )}

                    <span className="text-xs pl-2 font-normal">
                      (장내•장외 매수 내역
                      <button
                        className="text-orange-600 pl-1"
                        onClick={(e) => {
                          setShowBuyListTable((prev) => !prev);
                        }}
                      >
                        <div className="flex">
                          {showBuyListTable ? (
                            <span className="pl-1 flex items-center gap-1">
                              닫기 <BiHide />
                            </span>
                          ) : (
                            <span className="pl-1 flex items-center gap-1">
                              열기 <BiShow />
                            </span>
                          )}
                          <span className="text-white text-xs font-normal">
                            )
                          </span>
                        </div>
                      </button>
                    </span>
                  </span>
                  <div>
                    <form>
                      매수후
                      <select
                        className="bg-transparent border rounded mx-2"
                        onChange={handleChange}
                        // defaultValue={}
                        value={monitoringMonth}
                      >
                        <option value="1">1개월</option>
                        <option value="3">3개월</option>
                        <option value="6">6개월</option>
                        <option value="12">12개월</option>
                      </select>
                      주가 변동폭
                    </form>
                  </div>
                </div>

                <div className={`${!showBuyListTable ? "hidden" : ""} `}>
                  <TableContent
                    disableControlPanel={true}
                    isLoading={isBuyListLoading}
                    columns={history_columns}
                    data={buylistData}
                    handleChartInfo={
                      isBuyListLoading ? undefined : handleBuyListChartInfo
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`flex justify-between text-sm ${
              isLoading ? "hidden" : ""
            }`}
          >
            <div
              className="flex items-center gap-1 cursor-pointer pl-2 pb-0.5"
              onClick={handleCheckBox}
            >
              <input
                type="checkbox"
                checked={showPersonOnly}
                className={styles.showonly}
                onClick={handleCheckBox}
                id="showonly"
              />
              <label for="showonly"></label>
              <span className="font-bold text-sm text-text italic mr-5">
                직책 있는 거래자만
              </span>
            </div>
            <span className="font-bold text-sm text-text italic mr-5">
              {dateRange
                ? `${dateRange[0]} ~ ${dateRange[1]}`
                : `${data[data?.length - 1]?.date} ~ ${data[0]?.date}`}
            </span>
          </div>
        </TableContent>
      ) : (
        ""
      )}
    </>
  );
}
