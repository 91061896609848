import React from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen bg-bg text-text flex flex-col justify-center items-center">
      <p className="text-2xl">{"Page Error"}</p>
      <p>
        <button
          type="text"
          className="rounded border p-1 m-2 bg-slate-600 text-sm"
          onClick={(e) => {
            navigate("/");
          }}
        >
          Back to Main
        </button>
      </p>
    </div>
  );
}
