import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FaSpinner } from "react-icons/fa";
import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import { IconContext } from "react-icons/lib";
import { useEffect } from "react";

function DefaultFilterForColumn({
  column: { Header, filterValue, setFilter, id },
}) {
  var timer;
  const handleChange = (e) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setFilter(e.target.value || undefined);
      clearTimeout(timer);
    }, 400);

    // setFilter(e.target.value || undefined);
  };
  const handleRemove = (e) => {
    // console.log(document.getElementById(id).value);
    document.getElementById(id).value = "";

    setFilter(undefined);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="relative">
        <input
          className={`w-full py-1 pl-1 rounded text-sm inline ${
            filterValue ? " bg-sky-900" : "bg-bg text-text"
          }`}
          onChange={handleChange}
          placeholder={`${Header} 검색`}
          id={id}
        />
        {filterValue !== undefined ? (
          <IconContext.Provider
            value={{
              attr: {
                onClick: (e) => {
                  handleRemove(e);
                },
                onMouseOver: (e) => {
                  e.target.style.color = "white";
                  e.target.style.cursor = "hand";
                },
                onMouseOut: (e) => {
                  e.target.style.color = "gray";
                },
              },
              style: {
                display: "inline",
                position: "absolute",
                right: "0.1rem",
                top: "0.25rem",
                color: "gray",
                fontSize: "1.3rem",
              },
            }}
          >
            <TiDelete />
          </IconContext.Provider>
        ) : (
          ""
        )}
      </div>
    </form>
  );
}

export default function TableContent({
  isLoading,
  columns,
  data,
  itemPerPage,
  handleChartInfo,
  enableCompactControlPanel,
  disableControlPanel,
  children,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {
        Filter: DefaultFilterForColumn,
      },
      initialState: { pageIndex: 0, pageSize: itemPerPage ? itemPerPage : 30 },
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const randomSignatureForFlag = new Date().getUTCMilliseconds(); // 토글용 랜덤 id 생성
  const [selectedData, setSelectedData] = useState({});

  const ControlPanel = () => {
    return (
      <span className="flex items-baseline my-3 space-x-5 w-min ">
        <select
          className="text-sm rounded bg-slate-600"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 60, 80, 100].map((pageSize) => {
            return (
              <option className="text-sm" key={pageSize} value={pageSize}>
                {pageSize} 건씩
              </option>
            );
          })}
        </select>
        <button
          className={`${!canPreviousPage ? "text-gray-600" : ""}`}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className={`${!canPreviousPage ? "text-gray-600" : ""}`}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className={`${!canNextPage ? "text-gray-600" : ""}`}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className={`${!canNextPage ? "text-gray-600" : ""}`}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>

        <span className="flex items-center text-sm">
          <span>Page</span>
          <input
            className="w-8 mx-2 text-center bg-transparent border rounded"
            type="number"
            // defaultValue={pageIndex + 1}
            value={pageIndex + 1}
            onChange={(e) => {
              gotoPage(e.target.value - 1);
            }}
          />
          <span className="w-max">of {pageOptions.length}</span>
        </span>
      </span>
    );
  };

  const CompactControlPanel = () => {
    return (
      <span className="flex items-baseline justify-between px-2 my-1 space-x-2">
        <div>
          <button
            className={`${!canPreviousPage ? "text-gray-600" : ""} pr-2`}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
          <button
            className={`${!canNextPage ? "text-gray-600" : ""}`}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
        </div>
        <span className="flex items-baseline text-sm">
          <span className="px-1 text-center rounded">{pageIndex + 1}</span>
          <span className="w-max">of {pageOptions.length}</span>
        </span>
      </span>
    );
  };

  const handleClick = (e, row) => {
    if (!handleChartInfo) {
      return;
    }
    if (data[row.id].code !== undefined) {
      handleChartInfo({
        company_id: data[row.id].company_id,
        name: data[row.id].name,
        code: data[row.id].code,
        row: row,
      });

      const willremove = document.getElementsByName(randomSignatureForFlag); // 행 클릭시에 표시
      Array.from(willremove).forEach((item) => {
        item.classList.remove("bg-amber-50");
        item.classList.remove("text-bg");
      });

      e.currentTarget.classList.add("bg-amber-50");
      e.currentTarget.classList.add("text-bg");
      setSelectedData(data[row.index]);
    } else {
      handleChartInfo("", "", row);
    }
  };
  useEffect(() => {
    const willremove = document.getElementsByName(randomSignatureForFlag); // 행 클릭시에 표시
    Array.from(willremove).forEach((item) => {
      item.classList.remove("bg-amber-50");
      item.classList.remove("text-bg");
    });
    setSelectedData();
  }, [data[0]?.person]);
  // }, []);

  return (
    <div className="flex flex-col w-full px-4 pb-2 text-white">
      {!disableControlPanel ? (
        !enableCompactControlPanel ? (
          <ControlPanel />
        ) : (
          ""
        )
      ) : (
        <></>
      )}
      <div className="mb-0">{children}</div>
      <div
        className={`text-2xl grow justify-center inline-flex ${
          !isLoading ? "hidden" : ""
        }`}
      >
        <FaSpinner className="self-center mx-2 text-sm animate-spin" />
        <div className="self-center">Loading....</div>
      </div>
      {selectedData?.url ? (
        <div className="flex flex-col p-2 mb-2 text-sm font-bold text-center text-orange-500 border rounded sm:flex-row sm:justify-evenly bg-slate-700 border-slate-400">
          <a
            href={`${selectedData.url}`}
            target="_blank"
            className="pb-1 md:pb-0"
          >
            ✓ {selectedData.name} ({selectedData.date}) 공시 새창보기(Dart)
          </a>
          <a
            href={`https://finance.naver.com/item/coinfo.naver?code=${selectedData.code
              .toLowerCase()
              .replace("a", "")}`}
            target="_blank"
            className=""
          >
            ✓ {selectedData.name} 재무상태 새창보기(네이버)
          </a>
        </div>
      ) : (
        ""
      )}
      <table {...getTableProps()} className={isLoading ? "hidden" : ""}>
        <thead className="border-b-[0.5px] bg-slate-700">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="text-sm text-text ">
                  <div className="flex items-center px-1">
                    {!column.canSort && !column.canFilter
                      ? column.render("Header")
                      : null}
                  </div>
                  <div
                    className={`flex items-center px-1 py-1 ${
                      column.canFilter || column.canSort ? "" : "hidden"
                    }`}
                  >
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                    <div
                      className="mx-2 text-sm"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.canSort ? column.render("Header") : null}
                      <span>
                        {column.canSort
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? " ⬇︎"
                              : " ⬆︎"
                            : ""
                          : ""}
                      </span>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`hover:bg-indigo-100 h-8 max-h-[200px] text-sm hover:text-bg `}
                name={`${randomSignatureForFlag}`}
                onClick={(e) => {
                  handleClick(e, row); // 행 클릭시에 실행
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={`pl-2 ${
                      cell.column.id === "name" ? "w-40" : " max-w-[840px] "
                    }`}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <hr className="mt-1" />
      <div className="">
        {enableCompactControlPanel ? <CompactControlPanel /> : <></>}
      </div>
    </div>
  );
}
