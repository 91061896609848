import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ReviewsChart({ chartData }) {
  const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
      var group = el[key];
      if (carry[group] === undefined) {
        carry[group] = [];
      }
      carry[group].push(el);
      return carry;
    }, {});
  };

  const base_options = {
    // 기초스타일
    title: {
      style: {
        color: "white",
        fontWeight: "bold",
      },
    },
    chart: {
      // 차트 스타일 설정
      type: "line",
      backgroundColor: "transparent",
      plotBorderWidth: 1,
      zoomType: "x",
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    yAxis: [
      {
        title: {
          text: "건(일)",
        },
        gridLineColor: "transparent",
      },
      {
        title: {
          text: "건(월)",
        },
        gridLineColor: "transparent",
        opposite: true,
      },
    ],

    tooltip: {
      borderRadius: 20,
      shared: true,
    },
    plotOptions: {
      series: {
        // stacking: "normal",
        turboThreshold: 0,
      },
      // area: {
      //   fillOpacity: 0.1,
      // },
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "#33537970",
      },
    },
  };

  const [options, setOptions] = useState(base_options);

  useEffect(() => {
    // chart data 셋업

    const monthlygrouped = groupBy(chartData.data, "month");
    const monthlyData = [];
    for (const month in monthlygrouped) {
      let monthlySum = 0;
      monthlygrouped[month].forEach((item) => {
        monthlySum += item.y;
      });
      monthlyData.push({ x: Date.parse(month), y: monthlySum });
    }

    const data = {
      name: `${chartData.product} @ ${chartData.platform} `,
      data: chartData.data.map((item) => {
        return {
          x: Date.parse(item.x),
          y: item.y,
        };
      }),
    };

    const series = [
      {
        data: data.data,
        name: data.name + "(일)",
        color: "orange",
        marker: {
          radius: 3,
          lineColor: "red",
        },
        lineWidth: 1,
      },
      {
        data: monthlyData,
        name: data.name + "(월)",
        color: "white",
        marker: {
          radius: 3,
          lineColor: "red",
        },
        lineWidth: 1,
        yAxis: 1,
      },
    ];

    const title = {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: "",
    };

    setOptions((prev) => {
      const temp = {
        ...prev,
        series,
        title,
      };
      return temp;
    });
  }, [chartData]);

  return (
    <div className="w-full">
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"chart"}
        />
      </div>
    </div>
  );
}
