import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useMemo, useState } from "react";

export default function TransactionChart({ chartInfo: { company_id, name } }) {
  const base_options = useMemo(() => {
    let cumulativeLabel = null;
    return {
      // 기초스타일
      title: {
        style: {
          color: "white",
          fontWeight: "bold",
        },
        text: `${name}`,
        y: 50,
      },
      chart: {
        // 차트 스타일 설정
        backgroundColor: "transparent",
        plotBorderWidth: 1,
        height: `${(9 / 12) * 100}%`,
        // plotBackgroundColor: "white",
        zoomType: "x",
        marginTop: 50,
      },
      accessibility: {
        enabled: false,
      },
      colors: [
        "#058DC7",
        "#50B432",
        "#ED561B",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
      xAxis: {
        type: "datetime",
        crosshair: true,
        labels: {
          style: {
            color: "white",
          },
        },
        crosshair: true,
      },

      yAxis: [
        {
          labels: {
            align: "left",
            style: {
              color: "white",
            },
          },
          height: "18%",
          gridLineColor: "transparent",
          title: {
            text: "주가",
            textAlign: "left",
          },
          crosshair: true,
        },
        {
          labels: {
            align: "left",
            style: {
              color: "white",
            },
          },
          top: "18%",
          height: "6%",
          offset: 0,
          title: {
            text: "거래량",
            textAlign: "left",
          },
        },

        {
          labels: {
            align: "left",
            style: {
              color: "white",
            },
          },
          top: "24%",
          height: "38%",
          offset: 0,
          title: {
            text: "누적차트",
            textAlign: "left",
          },
          gridLineColor: "transparent",
          crosshair: true,
        },
        {
          labels: {
            align: "left",
            style: {
              color: "white",
            },
          },
          top: "63%",
          height: "37%",
          offset: 0,
          title: {
            text: "누적차트",
            textAlign: "left",
          },
          gridLineColor: "transparent",
          crosshair: true,
        },
      ],

      plotOptions: {
        series: {
          turboThreshold: 0,
          point: {
            events: {
              mouseOver: function () {
                const index = this.series.index;

                const chart = this.series.chart;
                const left = this.series.chart.plotLeft;
                const top = this.series.chart.plotTop;
                const x = this.plotX;
                const y = this.plotY;
                const plotHeight = this.series.chart.plotHeight;
                const plotWidth = this.series.chart.plotWidth;

                let cumulativeSum = this.cumulativeSum
                  ?.toFixed()
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                let name = this.series.name;
                let color = this.series.color;

                if (index > 1) {
                  cumulativeLabel = chart.renderer
                    .label(
                      `<div style="background-color: rgb(100,100,100); border-radius: 6px; padding: 2px">` +
                        cumulativeSum +
                        "</div>",
                      left + plotWidth + 5,
                      top + y + plotHeight * (index < 5 ? 0.24 : 0.63),
                      null,
                      null,
                      null,
                      true
                    )
                    .css({
                      fontSize: "12px",
                      color: color,
                    })
                    .attr({ zIndex: 9999 })
                    .add();
                }
              },
              mouseOut: function () {
                cumulativeLabel?.destroy();
                cumulativeLabel = null;
              },
            },
          },
        },
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },

      tooltip: {
        borderRadius: 20,
        headerShape: "callout",
        borderWidth: 0,
        shadow: false,
        backgroundColor: "#14110680",
        positioner: function (width, height, point) {
          var chart = this.chart,
            position;

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight
                )
              ),
              y: point.plotY / 2,
            };
          } else {
            if (point.series.name.includes("누적")) {
              position = {
                x: point.series.chart.plotLeft,
                y: point.series.yAxis.top,
              };
            } else {
              position = {
                x: point.series.chart.plotLeft,
                // y: point.series.yAxis.top + chart.plotTop,
                y: point.series.yAxis.top,
              };
            }
          }

          return position;
        },
        formatter: function () {
          const AddComma = (num) => {
            var regexp = /\B(?=(\d{3})+(?!\d))/g;
            return num.toString().replace(regexp, ",");
          };

          let s =
            "<span  style='color:white'><b>" +
            Highcharts.dateFormat("%Y-%m-%d", this.x) +
            "</b>";
          try {
            if (
              this.points[0].series.currentDataGrouping.unitName.includes(
                "week"
              )
            ) {
              s += "(Weekly 데이터)";
            } else {
              s += "(Daily 데이터)";
            }
          } catch (e) {
            s += "(Daily 데이터)";
          } finally {
            s += "</span>";
          }
          this.points.forEach(function (element) {
            if (element.series.name.includes("기관")) {
              s +=
                "<br/>" +
                "<b>" +
                "<span style='color:" +
                element.color +
                "'>" +
                element.series.name +
                " : " +
                AddComma(element.point.y.toFixed()) +
                "</span></b> <br/><br/>";
            } else if (element.series.name.includes("거래량")) {
              s +=
                "<br/><b>" +
                "<span style='color:" +
                element.color +
                "'>" +
                element.series.name +
                " : " +
                AddComma(element.point.y.toFixed()) +
                "</span></b><br/>";
            } else if (element.series.name.includes("종가")) {
              s +=
                "<br/><b>" +
                "<span style='color:" +
                element.color +
                "'>" +
                "종가 : " +
                AddComma(element.point.y.toFixed()) +
                "</span></b>";
            } else {
              s +=
                "<br/><b>" +
                "<span style='padding: 15px; color:" +
                element.color +
                "'>" +
                element.series.name +
                " : " +
                AddComma(element.point.y.toFixed()) +
                "</span> </b>";
            }
          });
          return s;
        },
        shared: true,
      },
      legend: {
        enabled: true,
        floating: true,
        verticalAlign: "bottom",
        y: -5,
        itemStyle: {
          color: "white",
          fontSize: "0.8rem",
        },
        itemHiddenStyle: {
          color: "#33537970",
        },
      },
      navigator: {
        series: {
          color: "#323232",
        },
        height: 20,
        top: 10,
      },
      scrollbar: {
        height: 0.5,
      },
      rangeSelector: {
        buttonTheme: {
          // styles for the buttons
          fill: "none",
          stroke: "none",
          "stroke-width": 0,
          r: 8,
          style: {
            color: "white",
            fontWeight: "bold",
          },
          states: {
            hover: {},
            select: {
              fill: "white",
              style: {
                color: "#039",
              },
            },
          },
        },
        inputStyle: {
          color: "white",
        },
        labelStyle: {
          color: "white",
        },
      },
    };
  });

  const [options, setOptions] = useState(base_options);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const title = {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: `${name}`,
    };

    axios
      .get(`http://stockinu.com:4000/api/transaction?company_id=${company_id}`)
      .then((r) => {
        return r.data;
      })
      .then((res) => {
        const chartdata = {
          price: { data: [], name: "주가" },
          volume: { data: [], name: "거래량" },
          individual: { data: [], name: "개인" },
          foreigner: { data: [], name: "외국인" },
          institute: { data: [], name: "기관" },
          financial: { data: [], name: "금융투자" },
          insurance: { data: [], name: "보험" },
          trust: { data: [], name: "투신" },
          etcfinance: { data: [], name: "기타금융" },
          bank: { data: [], name: "은행" },
          pension: { data: [], name: "연기금" },
          pef: { data: [], name: "사모" },
          nation: { data: [], name: "국가" },
          etccorp: { data: [], name: "기타법인" },
          inandout: { data: [], name: "내외국인" },
        };

        res.forEach((item) => {
          const parseddate = Date.parse(item.date);
          chartdata.price.data.push({ x: parseddate, y: item.close });
          chartdata.volume.data.push({ x: parseddate, y: item.volume });
          chartdata.individual.data.push({ x: parseddate, y: item.individual });
          chartdata.foreigner.data.push({ x: parseddate, y: item.foreigner });
          chartdata.institute.data.push({ x: parseddate, y: item.institute });
          chartdata.financial.data.push({ x: parseddate, y: item.financial });
          chartdata.insurance.data.push({ x: parseddate, y: item.insurance });
          chartdata.trust.data.push({ x: parseddate, y: item.trust });
          chartdata.etcfinance.data.push({ x: parseddate, y: item.etcfinance });
          chartdata.bank.data.push({ x: parseddate, y: item.bank });
          chartdata.pension.data.push({ x: parseddate, y: item.pension });
          chartdata.pef.data.push({ x: parseddate, y: item.pef });
          chartdata.nation.data.push({ x: parseddate, y: item.nation });
          chartdata.etccorp.data.push({ x: parseddate, y: item.etccorp });
          chartdata.inandout.data.push({ x: parseddate, y: item.inandout });
        });

        const tempseries = [];
        Object.keys(chartdata).forEach((key, idx) => {
          tempseries.push({
            type: key === "volume" ? "column" : "line",
            data: chartdata[key].data,
            name: chartdata[key].name,
            color: idx === 1 ? "white" : "",
            cumulative: !(idx === 0 || idx === 1),
            yAxis:
              key === "price"
                ? 0
                : key === "volume"
                ? 1
                : key === "individual" ||
                  key === "foreigner" ||
                  key === "institute"
                ? 2
                : 3,
            lineWidth: 1,
            visible: !(
              idx === 6 ||
              idx === 8 ||
              idx === 9 ||
              idx === 12 ||
              idx === 13 ||
              idx === 14
            ),
          });
        });

        return tempseries;
      })
      .then((ser) => {
        setOptions((prev) => {
          const temp = {
            ...prev,
            series: ser,

            title,
          };

          return temp;
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      setOptions(base_options);
    };
  }, [company_id]);

  return (
    <div className="w-full overflow-auto h-max">
      <div
        className={`w-full text-center bg-slate-500 text-bg p-5 rounded my-2 text-xlg font-bold ${
          !isLoading && "hidden"
        }`}
      >
        <FaSpinner className="inline mx-2 text-sm animate-spin" />
        loading....
      </div>

      <div className={`${isLoading && "hidden"} h-full `}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      </div>
    </div>
  );
}
