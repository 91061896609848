import axios from "axios";
import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

async function getConsensusChartData(company_id, year) {
  const revenues = [];
  const operatingProfits = [];
  await axios
    .get(
      `http://stockinu.com:4000/api/consensus?year=${year}&company_id=${company_id}`
    )
    .then((res) => {
      res.data.forEach((item) => {
        revenues.push({
          x: Date.parse(item.date),
          y: Number(item.revenue?.toFixed(2)),
        });
        operatingProfits.push({
          x: Date.parse(item.date),
          y: Number(item.operatingProfit?.toFixed(2)),
        });
      });
    });

  return { revenues, operatingProfits };
}
async function getStockChartData(company_id) {
  const newdata = [];
  await axios
    .get(
      `http://stockinu.com:4000/api/stockprice/company_id/${company_id}/limit/600`
    )
    .then((res) => {
      res.data.forEach((item) => {
        newdata.push({
          x: Date.parse(item.date), // milliseconds 단위
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
        });
      });
    });

  return newdata;
}

export default function ConsensusChart({ company_id, name }) {
  const base_options = {
    // 기초스타일
    title: {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: `${name} 컨센서스 추이`,
    },
    chart: {
      // 차트 스타일 설정
      type: "spline",
      backgroundColor: "transparent",
      // backgroundColor: "#14213D",
      plotBorderWidth: 1,
      // plotBackgroundColor: "#FCA31150",
      zoomType: "x",
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
      labels: {
        style: {
          color: "white",
        },
      },
    },

    yAxis: [
      {
        // 컨센차트
        title: {
          text: "컨센서스(억)",
          style: {
            color: "white",
          },
        },
        gridLineColor: "transparent",
        labels: {
          style: {
            color: "white",
          },
        },

        opposite: false,
      },
      {
        title: {
          // 주가차트
          text: "주가(원)",
          style: {
            color: "white",
          },
        },

        gridLineColor: "transparent",
        labels: {
          style: {
            color: "white",
          },
        },
      },
    ],
    rangeSelector: {
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 0,
        r: 8,
        style: {
          color: "white",
          fontWeight: "bold",
        },
        states: {
          select: {
            fill: "white",
            style: {
              color: "#039",
            },
          },
        },
      },
    },
    navigator: {
      height: 20,
    },

    scrollbar: {
      // 스크롤바
      height: 1,
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "gray",
      },
      enabled: true,
    },
    tooltip: {
      borderRadius: 20,
      valueDecimals: 0,
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        turboThreshold: 0,
      },
      candlestick: {
        lineColor: "orange",
        color: "orange",
        upLineColor: "red", // docs
        upColor: "red",
      },
    },
  };

  const [options, setOptions] = useState(base_options);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const today = new Date();
    const this_year = today.getFullYear().toString() + "12";
    const next_year = (today.getFullYear() + 1).toString() + "12";
    getConsensusChartData(company_id, this_year)
      .then((this_res) => {
        // chart data 셋업

        const series = [
          {
            data: this_res.revenues,
            name: `${this_year.slice(0, 4)}년 매출 컨센서스`,
            color: "white",
            visible: true,
            showInNavigator: false,
          },
          {
            data: this_res.operatingProfits,
            name: `${this_year.slice(0, 4)}년 영업이익 컨센서스`,
            color: "orange",
            visible: false,
          },
        ];
        getConsensusChartData(company_id, next_year).then((next_res) => {
          series.push({
            data: next_res.revenues,
            name: `${next_year.slice(0, 4)}년 매출 컨센서스`,
            color: "#F3E37C",
            visible: false,
            // showInNavigator: true,
          });
          series.push({
            data: next_res.operatingProfits,
            name: `${next_year.slice(0, 4)}년 영업이익 컨센서스`,
            color: "#D80032",
            visible: false,
            // showInNavigator: true,
          });

          const title = {
            style: {
              color: "white",
              fontWeight: "bold",
            },
            text: `${name} 컨센서스 추이`,
          };

          getStockChartData(company_id).then((res) => {
            // chart data 셋업

            series.push({
              type: "candlestick",
              data: res,
              events: {
                click: function (e) {
                  console.log(e);
                },
              },
              name: "주가",
              yAxis: 1,
              showInNavigator: true,
            });

            setOptions((prev) => {
              const temp = {
                ...prev,
                series,
                title,
              };
              return temp;
            });
          });
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [company_id]);
  return (
    <div className="w-full">
      <div
        className={`w-full text-center bg-slate-500 text-bg p-5 rounded my-2 text-xlg font-bold ${
          !isLoading && "hidden"
        }`}
      >
        ....loading....
      </div>

      <div className={`${isLoading ? "hidden" : ""}`}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"stockChart"}
        />
      </div>
    </div>
  );
}
