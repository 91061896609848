import React from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import RouterChangeTracker from "../services/RouterChangeTracker";
import Sidebar from "./Sidebar";

export default function Root() {
  const [toggleMenu, setToggleMenu] = useState(false); // 모바일용 toggle flag

  RouterChangeTracker();
  return (
    <main className="flex flex-col md:flex-row h-screen w-screen short:flex short:flex-col">
      <div className="bg-accent md:h-full h-12 short:h-12">
        <Sidebar toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
      </div>

      <div className={`bg-bg w-full h-screen overflow-y-auto relative`}>
        {toggleMenu ? (
          <div
            onClick={(e) => {
              if (toggleMenu) {
                setToggleMenu(false);
              }
            }}
            className={`w-full h-full z-10 absolute bg-transparent`}
          >
            {toggleMenu.toString()}
          </div>
        ) : (
          <></>
        )}
        <div className="text-slate-200 italic text-xs px-6 py-2 z-0 flex flex-col md:flex-row">
          <p>
            ✓ 본 사이트는 PC에 최적화되어 있습니다. 각 행을 클릭하시면 해당
            기업의 관련 차트가 그려지게 됩니다.
          </p>
          <p>
            ✓ 문의사항이나 개선사항이 있으시면 stockinu.com@gmail.com 으로
            연락주세요.
          </p>
        </div>
        <hr className="mx-4 border-slate-500 z-0 " />
        <div className="h-full pb-10 ">
          <Outlet />
        </div>
      </div>
    </main>
  );
}
