import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SlMenu } from "react-icons/sl";
import { useState } from "react";

const menus = [
  ["임원주주변동상황", "shares"],
  ["대량보유상황", "massshares"],
  ["보호예수해제", "freetosell"],
  [""], // divider 역할
  ["실적변동공시", "updown"],
  ["컨센서스변동", "consensus"],
  [""], // divider 역할

  // ["종목별 주가"],
  ["종목별 거래량", "transaction"],
  // ["연속순매매동향", "nettrade"],
  ["고용트렌드", "employees"],
  [""], // divider

  ["수주잔고", "backlogs"],
  ["수출통계", "export"],
  // ["상장사 상표출원 추적", "trademark"],
  ["가맹점추적", "stores"],
  ["리뷰트렌드", "reviews"],
  [""], // divider
  ["재무상태변동", "fstatement"],
  // ["재고자산변동", "inventory"],
  // ["선수금변동", "advanced"],
  // ["장기선수금변동", "long_advanced"],
  // ["선수수익변동", "unearned"],
  // ["장기선수수익변동", "long_unearned"],
  // ["계약부채변동", "contract"],
  // ["장기계약부채변동", "long_contract"],
  [""], // divider
];

export default function Sidebar({ toggleMenu, setToggleMenu }) {
  const baseClassName =
    "menu text-start pl-4 text-sm rounded-l-lg py-1 hover:bg-bg-light hover:text-accent w-full";
  const selectedClass =
    "menu text-start pl-4 text-sm font-black rounded-l-lg py-1 text-accent bg-bg";
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = useState(menus[0][0]);

  useEffect(() => {
    // 선택 메뉴 하일라이트
    const idx = menus.findIndex(([item, address]) => {
      return location.pathname.split("/")[1] === address;
    });
    setSelectedMenu(menus[idx] ? menus[idx][0] : menus[0][0]);
    const elements = document.getElementsByClassName("menu");
    Array.prototype.forEach.call(elements, (item) => {
      item.className = baseClassName;
    });
    try {
      if (idx === -1) {
      } else {
        const selected = document.getElementById(idx); // subpath가 없으면, 즉, 메인 화면이면, 맨처음(임원주주변동상황)의 메뉴를 자동 선택하도록!!
        selected.className = selectedClass;
      }
    } catch {}
  }, [location]);

  const handleClick = (e, address, idx) => {
    navigate(`/${address}`);
    setSelectedMenu(menus[idx][0]);
  };

  return (
    <>
      {/* 반응형 메뉴 - 데스크탑 */}
      <div className="md:block hidden h-full short:hidden">
        <div className="h-full text-bg pl-5 flex flex-col justify-around w-full">
          <button
            className="text-lg font-black mt-12 mb-8 text-start pl-6"
            onClick={(e) => {
              navigate("/");
            }}
          >
            STOCKINU
          </button>
          <hr className="mb-8 border-bg" />
          <div className=" flex flex-col flex-1 space-y-0.5">
            {menus.map(([item, address], idx) => {
              return item === "" ? (
                // <div key={idx} className="py-2"></div>
                <div key={idx} className="py-2 pl-4">
                  <hr className=" border-bg-light" />
                </div>
              ) : (
                <button
                  key={idx}
                  id={idx}
                  className={`${idx === 0 ? selectedClass : baseClassName}`}
                  onClick={(e) => handleClick(e, address, idx)}
                >
                  {item}
                </button>
              );
            })}
          </div>
          <div className="mb-5 mr-2 bottom-2 text-xs font-bold">
            stockinu.com@gmail.com
          </div>
        </div>
      </div>
      {/* 반응형 메뉴 - 모바일 */}
      <div className={`md:hidden h-full short:block `}>
        <div className="px-4 text-lg text-bg h-full flex flex-col justify-center">
          <div className="flex items-center">
            <SlMenu
              className={`${toggleMenu && " rotate-90 "}`}
              onClick={(e) => {
                setToggleMenu((prev) => !prev);
              }}
            />
            <div className="flex-1 text-center text-sm italic font-bold">
              {selectedMenu}
            </div>
          </div>
        </div>
        <ul
          className={`${
            !toggleMenu ? "hidden" : ""
          } bg-stone-700 relative z-20 mx-1 text-text rounded-b border-l border-r border-b`}
        >
          {menus.map(([item, address], idx) => {
            {
              return item === "" ? (
                <li key={idx}>
                  <div className="p-2"></div>
                </li>
              ) : (
                <li
                  key={idx}
                  className="w-full"
                  onClick={(e) => {
                    setToggleMenu(false);
                    handleClick(e, address, idx);
                  }}
                >
                  <button
                    id={idx}
                    className={`${
                      idx === 0 ? selectedClass : baseClassName
                    } w-full`}
                  >
                    {item}
                  </button>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </>
  );
}
