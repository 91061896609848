import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ReviewsChart from "../../components/ReviewsChart";
import TableContent from "../TableContent";

export default function Reviews() {
  const [isLoading, setIsLoading] = useState(false);
  // const [chartInfo, setChartInfo] = useState(null);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState();

  const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
      var group = el[key];
      if (carry[group] === undefined) {
        carry[group] = [];
      }
      carry[group].push(el);
      return carry;
    }, {});
  };

  async function getChartData(code) {
    const newdata = [];

    await axios
      .get(`http://stockinu.com:4000/api/reviews/code/${code}`)
      .then((res) => {
        var groups = ["platform", "product_name"];
        var grouped = {};

        res.data.forEach(function (a) {
          groups
            .reduce(function (o, g, i) {
              // take existing object,
              o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
              return o[a[g]]; // at last, then an array
            }, grouped)
            .push(a);
        });

        for (const platform in grouped) {
          const grouped_by_platform = grouped[platform];
          for (const product in grouped_by_platform) {
            const product_data = {
              platform: platform,
              product: product,
              data: [],
            };
            grouped_by_platform[product].forEach((item) => {
              product_data.data.push({
                x: item.date,
                y: item.count,
                month: item.date.slice(0, 7),
              });
            });
            newdata.push(product_data);
          }
        }
      })
      .finally(() => {});

    setChartData(newdata);
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/reviews/list");
      const temp = groupBy(res.data, "code");
      const result = [];
      for (const code in temp) {
        let company_temp_data = { code: code, name: temp[code][0].name };
        const temp_platform = [];
        const temp_product = [];
        const temp_updatedat = [];
        temp[code].forEach((element) => {
          temp_platform.push(element.platform);
          temp_product.push(element.product_name);
          temp_updatedat.push(element.updatedat);
        });
        company_temp_data = {
          ...company_temp_data,
          platform: [...new Set(temp_platform)].join(", "),
          product: [...new Set(temp_product)].join(", "),
          updatedat: temp_updatedat.sort(
            (a, b) => Date.parse(b) - Date.parse(a)
          )[0],
        };
        result.push(company_temp_data);
      }

      setData(result);
      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ code }) => {
    getChartData(code);
  };

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "회사명",
        // disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "platform",
        Header: "플랫폼",
        // disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "product",
        Header: "제품군",
        // disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "updatedat",
        Header: "업데이트",
        disableFilters: true,
        // disableSortBy: true,
      },

      // { accessor: "url", Header: "url", type: "url" },
    ],
    []
  );

  return (
    <>
      <TableContent
        isLoading={isLoading}
        columns={columns}
        data={data}
        itemPerPage={20}
        handleChartInfo={handleChartInfo}
      />

      {chartData &&
        chartData.map((productData) => {
          return <ReviewsChart chartData={productData} />;
        })}
    </>
  );
}
