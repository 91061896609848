import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TableContent from "../TableContent";

export default function FreeToSell() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/freetosell");
      setData(res.data);
      setIsLoading(false);
    })();
  }, []);

  const defaultNumberSort = useMemo(
    () => (rowA, rowB, columnId) =>
      Number(rowA.values[columnId]) >= Number(rowB.values[columnId]) ? 1 : -1
  );

  const columns = useMemo(
    () => [
      { accessor: "return_date", Header: "해제일", disableFilters: true },
      {
        accessor: "name",
        Header: "회사명",
        disableSortBy: true,
      },
      { accessor: "market", Header: "거래시장", disableSortBy: true },

      {
        accessor: "return_qty",
        Header: "해제주식수",
        disableFilters: true,
        Cell: ({ value }) => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        sortType: defaultNumberSort,
      },
      {
        accessor: "total_qty",
        Header: "총주식수",
        disableFilters: true,
        Cell: ({ value }) => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        sortType: defaultNumberSort,
      },
      { accessor: "reason", Header: "사유", disableSortBy: true },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      needChart={false}
    />
  );
}
