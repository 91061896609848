import React, { useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function OliveyoungBrandChart({ brandData }) {
  const base_options = useMemo(() => {
    return {
      // 기초스타일
      title: {
        style: {
          color: "white",
          fontWeight: "bold",
        },
      },
      colors: [
        "#058DC7",
        "#50B432",
        "#ED561B",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
      chart: {
        // 차트 스타일 설정
        type: "line",
        backgroundColor: "transparent",
        plotBorderWidth: 1,
        zoomType: "x",
      },
      accessibility: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
      },

      tooltip: {
        borderRadius: 20,
        shared: true,
      },
      plotOptions: {
        series: {
          // stacking: "normal",
          turboThreshold: 0,
        },
        // area: {
        //   fillOpacity: 0.1,
        // },
      },
      legend: {
        itemStyle: {
          color: "white",
          fontSize: "0.8rem",
        },
        itemHiddenStyle: {
          color: "#33537970",
        },
      },
    };
  });

  const [options, setOptions] = useState(base_options);

  useEffect(() => {
    // chart data 셋업

    const series = [];

    for (const menu in brandData["brand_data"]) {
      const menu_data = brandData["brand_data"][menu];

      series.push({
        name: menu,
        data: menu_data.map((item) => {
          return { x: Date.parse(item.date), y: item.count };
        }),
        // color: "orange",
        marker: {
          radius: 3,
          lineColor: "red",
        },
        lineWidth: 1,
      });
    }

    const title = {
      style: {
        color: "white",
        fontSize: 14,
      },
      text: "",
    };

    const yAxis = [
      {
        title: {
          text: `건(${brandData.brand} in TOP 100)`,
          style: {
            color: "white",
          },
        },
        gridLineColor: "transparent",
      },
    ];

    setOptions((prev) => {
      const temp = {
        ...prev,
        series,
        title,
        yAxis,
      };
      return temp;
    });
  }, [brandData]);

  return (
    <div className="w-full">
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"chart"}
        />
      </div>
    </div>
  );
}
