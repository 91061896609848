import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import EmployeesChart from "../../components/EmployeesChart";
import TableContent from "../TableContent";

export default function Employees() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get(
        "http://stockinu.com:4000/api/employeesgrowth"
      );

      setData(res.data);
      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ company_id, name, code }) => {
    setChartInfo({ company_id, name, code });
  };

  const defaultNumberSort = useMemo(
    () => (rowA, rowB, columnId) =>
      Number(rowA.values[columnId]) >= Number(rowB.values[columnId]) ? 1 : -1
  );

  const columns = useMemo(
    () => [
      { accessor: "name", Header: "회사명", disableSortBy: true },
      { accessor: "now", Header: "이번달(명)", disableFilters: true },
      { accessor: "last", Header: "지난달(명)", disableFilters: true },
      {
        accessor: "growth",
        Header: "변동율(%)",
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value}
            </span>
          );
        },
        type: "change",
        sortType: defaultNumberSort,
      },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      handleChartInfo={handleChartInfo}
    >
      {chartInfo?.company_id ? (
        <EmployeesChart code={chartInfo?.code} name={chartInfo?.name} />
      ) : (
        <></>
      )}
    </TableContent>
  );
}
