import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TableContent from "../../TableContent";

export default function Ilooda() {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const handleDuplicate = (dt) => {
    // 동일한 데이터 제거
    let temp = {};
    const reversed = (arr, asc) =>
      arr.sort((a, b) => {
        return asc
          ? Date.parse(a.date) - Date.parse(b.date)
          : Date.parse(b.date) - Date.parse(a.date);
      });

    const reversedData = reversed(dt, true).filter((item) => {
      const { date, ...prep } = item;
      if (JSON.stringify(temp) === JSON.stringify(prep)) {
        return false;
      } else {
        temp = { ...prep };
        return true;
      }
    });

    return reversed(reversedData, false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/ilooda/reepot");
      setData(handleDuplicate(res.data));
      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "일자",
        accessor: "date",
        disableFilters: true,
      },
      {
        Header: "Reepot Laser(병원수)",
        accessor: "count",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      disableControlPanel={true}
    />
  );
}
