import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import TableContent from "../../TableContent";

export default function Kcar() {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/kcar");

      setData(
        res.data
          .slice(1, res.data.length)
          .map((item, idx) => {
            return {
              date: item.date,
              totalCarSold: item.totalCarSold - res.data[idx].totalCarSold,
              totalCarsToBuyCounts: item.totalCarsToBuyCounts,
              directCarsToBuyCounts: item.directCarsToBuyCounts,
              certifiedCarsToBuyCounts: item.certifiedCarsToBuyCounts,
              totalCarBought:
                item.totalCarBought - res.data[idx].totalCarBought,
            };
          })
          .reverse()
      );

      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      { accessor: "date", Header: "일자", disableFilters: true },
      {
        accessor: "totalCarSold",
        Header: "내차팔기거래건수",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "totalCarBought",
        Header: "내차사기거래건수",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "totalCarsToBuyCounts",
        Header: "내차사기리스트총차량수",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "directCarsToBuyCounts",
        Header: "내차사기리스트직영차수",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "certifiedCarsToBuyCounts",
        Header: "내차사기리스트브랜드인증차수",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  return <TableContent isLoading={isLoading} columns={columns} data={data} />;
}
