import axios from "axios";
import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

async function getBacklogData(company_id) {
  const newdata = [];
  await axios
    .get(`http://stockinu.com:4000/api/backlog/company_id/${company_id}`)
    .then((res) => {
      res.data.forEach((item) => {
        newdata.push({
          x: Date.parse(item.date), // milliseconds 단위
          y: Number(item.amount ? (item.amount / 100000000).toFixed(1) : 0),
        });
      });
    })
    .finally(() => {});

  return newdata;
}

async function getStockChartData(company_id) {
  const newdata = [];
  await axios
    .get(`http://stockinu.com:4000/api/stockprice/company_id/${company_id}`)
    .then((res) => {
      res.data.forEach((item) => {
        newdata.push({
          x: Date.parse(item.date), // milliseconds 단위
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
        });
      });
    });

  return newdata;
}

export default function BacklogsChart({ company_id, name }) {
  const base_options = {
    // 기초스타일
    title: {
      style: {
        color: "white",
        fontWeight: "bold",
      },
      text: `${name} 고용추이`,
    },
    chart: {
      // 차트 스타일 설정
      backgroundColor: "transparent",
      plotBorderWidth: 1,
      zoomType: "x",
    },
    accessibility: {
      enabled: false,
    },

    yAxis: [
      {
        title: {
          text: "잔고(억)",
        },
        gridLineColor: "transparent",
        opposite: false,
      },
      {
        title: {
          // 주가차트
          text: "주가(원)",
          style: {
            color: "white",
          },
        },

        gridLineColor: "transparent",
        labels: {
          style: {
            color: "white",
          },
        },
      },
    ],
    rangeSelector: {
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 0,
        r: 8,
        style: {
          color: "white",
          fontWeight: "bold",
        },
        states: {
          select: {
            fill: "white",
            style: {
              color: "#039",
            },
          },
        },
      },
    },

    tooltip: {
      borderRadius: 20,
    },
    legend: {
      itemStyle: {
        color: "white",
        fontSize: "0.8rem",
      },
      itemHiddenStyle: {
        color: "gray",
      },
      enabled: true,
    },
    navigator: {
      height: 20,
    },

    scrollbar: {
      // 스크롤바
      height: 1,
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        turboThreshold: 0,
      },
      candlestick: {
        lineColor: "orange",
        color: "orange",
        upLineColor: "red", // docs
        upColor: "red",
      },
    },
  };

  const [options, setOptions] = useState(base_options);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getBacklogData(company_id)
      .then((res) => {
        // chart data 셋업

        // const xAxis = [
        //   {
        //     type: "datetime",
        //     crosshair: true,
        //     tickPositions: res.map((item) => item.x),
        //     labels: {
        //       formatter() {
        //         return Highcharts.dateFormat("%y-%m", this.value);
        //       },
        //     },
        //   },
        // ];

        const series = [
          {
            type: "area",
            data: res,
            name: "수주잔고",
            color: "rgb(98, 255, 122)",
            fillColor: "rgba(98, 255, 122, 0.1)",
            marker: {
              radius: 3,
              lineColor: "green",
            },
            lineWidth: 3,
          },
        ];

        const title = {
          style: {
            color: "white",
            fontWeight: "bold",
          },
          text: `${name} 수주잔고추이`,
        };

        getStockChartData(company_id).then((res) => {
          // chart data 셋업
          // xAxis.push({
          //   type: "datetime",
          // });
          series.push({
            type: "candlestick",
            data: res,
            events: {
              click: function (e) {
                console.log(e);
              },
            },
            name: "주가",
            yAxis: 1,
            // xAxis: 1,
            showInNavigator: true,
          });

          setOptions((prev) => {
            const temp = {
              ...prev,
              series,
              title,
              // xAxis,
            };
            return temp;
          });
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // return console.log("unmounting backlog chart");
  }, [company_id]);
  return (
    <div className="w-full">
      <div
        className={`w-full text-center bg-slate-500 text-bg p-5 rounded my-2 text-xlg font-bold ${
          !isLoading && "hidden"
        }`}
      >
        ....loading....
      </div>
      <div className={`${isLoading && "hidden"}`}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"stockChart"}
        />
      </div>
    </div>
  );
}
