import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import StockChart from "../../components/StockChart";

import TableContent from "../TableContent";
import styles from "./Shares.module.css";

export default function MassShares() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState({});
  const [data, setData] = useState([]);

  // **************** date filter *******************************
  const DateRangeColumnFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) => {
    const [min, max] = useMemo(() => {
      let max = filterValue[1]
        ? filterValue[1]
        : preFilteredRows[0]?.values[id];
      let min = filterValue[0]
        ? filterValue[0]
        : preFilteredRows[preFilteredRows?.length - 1]?.values[id];

      return [min, max];
    }, [filterValue]);

    return (
      <div className="flex items-center gap-2">
        <div className="flex flex-col gap-0.5">
          <input
            className={
              "bg-bg text-text font-normal text-xs rounded px-1 " +
              styles.inputcalendar
            }
            value={filterValue[0] ? filterValue[0] : min}
            type="date"
            min={preFilteredRows[preFilteredRows?.length - 1]?.values[id]}
            max={
              preFilteredRows[0]?.values[id] > max
                ? max
                : preFilteredRows[0]?.values[id]
            }
            onChange={(e) => {
              console.log(e);
              const val = e.target.value;

              setFilter([val, max]);
            }}
            placeholder={`Min (${min})`}
          />

          <input
            className={
              "bg-bg text-text font-normal text-xs rounded px-1 " +
              styles.inputcalendar
            }
            value={filterValue[1] ? filterValue[1] : max}
            type="date"
            min={
              preFilteredRows[preFilteredRows?.length - 1]?.values[id] < min
                ? min
                : preFilteredRows[preFilteredRows?.length - 1]?.values[id]
            }
            max={preFilteredRows[0]?.values[id]}
            onChange={(e) => {
              const val = e.target.value;

              setFilter([min, val]);
            }}
            placeholder={`Max (${max})`}
          />
        </div>
      </div>
    );
  };

  const betweenDate = useMemo(() => (rows, id, filterValue) => {
    return rows.filter(
      (row) =>
        row.values[id] >= filterValue[0] && row.values[id] <= filterValue[1]
    );
  });

  // ****************************************************************

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/massshares");
      setData(res.data);
      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ company_id, name }) => {
    setChartInfo({ company_id, name });
  };

  const defaultNumberSort = useMemo(
    () => (rowA, rowB, columnId) =>
      Number(rowA.values[columnId]) >= Number(rowB.values[columnId]) ? 1 : -1
  );

  const columns = useMemo(
    () => [
      {
        accessor: "date",
        Header: "공시일",
        // disableFilters: true,
        // type: "date",
        Filter: DateRangeColumnFilter,
        filter: betweenDate,
      },
      { accessor: "name", disableSortBy: true, Header: "회사명" },
      { accessor: "holder", disableSortBy: true, Header: "보고자" },
      {
        accessor: "current_ratio",
        disableFilters: true,
        Cell: ({ value }) => {
          return value + "%";
        },
        Header: "신고지분율",
        sortType: defaultNumberSort,
      },
      {
        accessor: "previous_ratio",
        disableFilters: true,
        Cell: ({ value }) => {
          return value + "%";
        },
        Header: "이전지분율",
        sortType: defaultNumberSort,
      },
      {
        accessor: (row, index) => {
          return row.current_ratio - row.previous_ratio;
        },

        Cell: ({ value }) => {
          return value === 0 ? (
            "-"
          ) : (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value.toFixed(2)}%
            </span>
          );
        },
        disableFilters: true,
        Header: "변동",
      },
      // { accessor: "url", Header: "url", type: "url" },
      {
        accessor: "reason",
        disableSortBy: true,
        Header: "사유",
      },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      handleChartInfo={handleChartInfo}
    >
      {chartInfo?.company_id ? (
        <StockChart company_id={chartInfo?.company_id} name={chartInfo?.name} />
      ) : (
        <></>
      )}
    </TableContent>
  );
}
