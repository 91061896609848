import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ExportChart from "../../components/ExportChart";
import TableContent from "../TableContent";

export default function Export() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState();
  const [data, setData] = useState([]);

  const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
      var group = el[key];
      if (carry[group] === undefined) {
        carry[group] = [];
      }
      carry[group].push(el);
      return carry;
    }, {});
  };
  const handleChartInfo = ({ company_id, name }) => {
    getChartData(company_id);
  };

  async function getChartData(company_id) {
    const newdata = [];
    await axios
      .get(`http://stockinu.com:4000/api/export/company_id/${company_id}`)
      .then((res) => {
        const temp = groupBy(res.data, "product");
        setChartData(temp);
      })
      .finally(() => {});

    return newdata;
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/export/recent");

      const temp_last = groupBy(res.data[0], "company_id");
      const temp_prev = groupBy(res.data[1], "company_id");
      const result = [];
      for (const company_id in temp_last) {
        const temp_company = {
          code: temp_last[company_id][0].code,
          name: temp_last[company_id][0].name,
          company_id: temp_last[company_id][0].company_id,
          date: temp_last[company_id][0].date,
          product: "",
          current: 0,
          previous: 0,
        };
        const temp_product = [];
        temp_last[company_id].forEach((item) => {
          temp_company.current += item.amountDollar;
          temp_product.push(item.product);
        });
        temp_company.product = [...new Set(temp_product)].join(", ");

        temp_prev[company_id].forEach((item) => {
          temp_company.previous += item.amountDollar;
        });
        result.push(temp_company);
      }

      setData(result);

      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "회사",
        accessor: "name",
        // disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "제품",
        accessor: "product",
        // disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: `${data[0]?.date.slice(0, 7)} 수출(천$)`,
        accessor: "current",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        Header: "전월수출(천$)",
        accessor: "previous",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        Header: "변동(%)",
        // accessor: "previous",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const val = (
            (row.values.current / row.values.previous - 1) *
            100
          ).toFixed(1);
          return (
            <span
              className={`${val < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {val}
            </span>
          );
        },
      },
    ],
    [data]
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      handleChartInfo={handleChartInfo}
    >
      {chartData &&
        Object.keys(chartData).map((product) => {
          return (
            <ExportChart chartData={chartData[product]} product={product} />
          );
        })}
    </TableContent>
  );
}
