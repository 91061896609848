import React, { useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import TableContent from "../TableContent";

const storelist = [
  {
    name: "골프존",
    detail: "가맹점/비가맹, 시스템수, 경쟁사 추이",
    code: "A215000",
    path: "golfzon",
  },
  {
    name: "제이시스메디칼",
    detail: "포텐자/리니어펌/리니어지 제품 도입 병원수 추이",
    code: "A287410",
    path: "jeisys",
  },
  // {
  //   name: "케이카",
  //   detail: "플랫폼 내 중고차 거래 추이",
  //   code: "A381970",
  //   path: "kcar",
  // },
  // {
  //   name: "아프리카TV",
  //   detail: "아프리카TV트렌드",
  //   code: "A067160",
  //   path: "afreecatv",
  // },

  {
    name: "에스제이그룹",
    detail: "캉골키즈/캉골/헬렌카민스키/팬암/LCDC 매장 추이",
    code: "A306040",
    path: "sjgroup",
  },
  {
    name: "감성코퍼레이션",
    detail: "스노우피크 매장 추이",
    code: "A036620",
    path: "gamseong",
  },
  {
    name: "이루다",
    detail: "리팟 병원 추이",
    code: "A164060",
    path: "ilooda",
  },
  {
    name: "디지털대성",
    detail: "한우리독서논술 센터 추이",
    code: "A068930",
    path: "daesung",
  },
  // {
  //   name: "교촌에프앤비",
  //   detail: "	교촌치킨 해외매장 추이",
  //   code: "A339770",
  //   path: "kyochon",
  // },
  {
    name: "올리브영",
    detail: "올리브영 입점 브랜드 추이",
    code: "",
    path: "oliveyoung",
  },
];

export default function Stores() {
  // const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState(null);
  const navigate = useNavigate();

  const handleChartInfo = ({ code, name }) => {
    const idx = storelist.findIndex((item) => item.name === name);
    const path = storelist[idx].path;
    const detail = storelist[idx].detail;

    setChartInfo((prev) => {
      return { code, name, path, detail };
    });
    navigate(path);
  };

  const columns = useMemo(() => [
    {
      accessor: "name",
      Header: "회사명",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: "detail",
      Header: "세부내용",
      disableFilters: true,
      disableSortBy: true,
    },
  ]);

  return (
    <>
      <TableContent
        // isLoading={isLoading}
        columns={columns}
        data={storelist}
        handleChartInfo={handleChartInfo}
        disableControlPanel={true}
      ></TableContent>
      {chartInfo ? (
        <div className="m-5 border rounded bg-slate-800 border-neutral-600">
          <div className="text-center rounded text-text">
            <div className="px-6 py-4 font-bold rounded-md bg-slate-800">
              {chartInfo?.name}
              <span className="text-sm">({chartInfo?.detail})</span>
            </div>
          </div>
          <Outlet />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
