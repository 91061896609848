import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import OliveyoungBrandGroupChart from "../../../components/stores/OliveyoungBrandGroupChart";
import TableContent from "../../TableContent";
import { FaSpinner } from "react-icons/fa";

export default function Oliveyoung() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState();
  const [isChartLoading, setIsChartLoading] = useState(false); // 자식 차트 컴포넌트가 로딩중인지 판단하는 state. 만약 로딩중이면 테이블에 클릭 이벤트를 안넘김!!

  const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
      var group = el[key];

      if (carry[group] === undefined) {
        carry[group] = [];
      }

      carry[group].push(el);
      return carry;
    }, {});
  };
  const handleChartLoading = (underLoading) => {
    setIsChartLoading(underLoading);
  };

  const handleChartInfo = (a, b, row) => {
    setChartData((prev) => {
      const temp = [];
      for (const key in row.values) {
        if (key === "date") {
          continue;
        }
        temp.push(row.values[key]);
      }

      return temp;
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get(
        "http://stockinu.com:4000/api/oliveyoung/recent/days/5"
      );

      const new_temp = groupBy(res.data[2], "menu");
      const final_res = {};
      for (const menu in new_temp) {
        final_res[menu] = [];
        const data_by_date = groupBy(new_temp[menu], "date");

        for (const date in data_by_date) {
          const temp_data = { date: date };
          data_by_date[date].forEach((item, idx) => {
            temp_data[`TOP${idx + 1}`] = `${item.brand}(${item.count})`;
          });
          final_res[menu].push(temp_data);
        }
      }
      setData(final_res);

      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(() => [
    {
      Header: "일자",
      accessor: "date",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP1",
      accessor: "TOP1",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP2",
      accessor: "TOP2",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP3",
      accessor: "TOP3",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP4",
      accessor: "TOP4",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP5",
      accessor: "TOP5",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP6",
      accessor: "TOP6",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "TOP7",
      accessor: "TOP7",
      disableFilters: true,
      disableSortBy: true,
    },
  ]);

  return (
    <>
      <div
        className={`w-full text-center bg-slate-500 text-bg p-5 rounded my-2 text-xlg font-bold ${
          !isChartLoading && "hidden"
        }`}
      >
        <FaSpinner className="animate-spin mx-2 text-sm inline" />
        loading....
      </div>
      <OliveyoungBrandGroupChart
        chartData={chartData}
        handleChartLoading={handleChartLoading}
      />

      {Object.keys(data).map((menu, idx) => {
        return (
          <div key={idx}>
            <span className="text-text px-10 text-sm">
              {idx}. {menu}
            </span>
            <TableContent
              isLoading={isLoading}
              columns={columns}
              data={data[menu]}
              disableControlPanel={true}
              handleChartInfo={!isChartLoading ? handleChartInfo : undefined}
            />
          </div>
        );
      })}
    </>
  );
}
