import axios from "axios";
import React, { useEffect, useState } from "react";
import OliveyoungBrandChart from "./OliveyoungBrandChart";

export default function OliveyoungBrandGroupChart({
  chartData,
  handleChartLoading,
}) {
  const [dataGrouppedByBrands, setDataGrouppedByBrands] = useState(undefined);

  const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
      var group = el[key];
      if (carry[group] === undefined) {
        carry[group] = [];
      }
      carry[group].push(el);
      return carry;
    }, {});
  };

  const getData = async () => {
    const data = await Promise.all(
      chartData?.slice(0, 5).map(async (brand) => {
        const revisedBrand = brand.split("(")[0];
        const url = `http://stockinu.com:4000/api/oliveyoung/brand/${revisedBrand}/`;
        const brand_data = await axios.get(url).then((res) => res.data);
        return {
          brand: revisedBrand,
          brand_data: groupBy(brand_data, "menu"),
        };
      })
    );

    return data;
  };

  useEffect(() => {
    handleChartLoading(true);
    const dt = getData()
      .then((res) => {
        setDataGrouppedByBrands(res);
      })
      .finally(() => {
        handleChartLoading(false);
      });
  }, [chartData]);

  return (
    <div>
      {dataGrouppedByBrands?.map((item, idx) => {
        return <OliveyoungBrandChart key={idx} brandData={item} />;
      })}
    </div>
  );
}
