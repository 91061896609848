import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import SharesStockChart from "../../components/StockChart";
import TableContent from "../TableContent";
import styles from "./Updown.module.css";

export default function Updown() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState({});
  const [data, setData] = useState([]);

  // **************** date filter *******************************

  const DateRangeColumnFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) => {
    const [min, max] = useMemo(() => {
      let max = filterValue[1]
        ? filterValue[1]
        : preFilteredRows[0]?.values[id];
      let min = filterValue[0]
        ? filterValue[0]
        : preFilteredRows[preFilteredRows?.length - 1]?.values[id];

      return [min, max];
    }, [filterValue]);

    return (
      <div className="flex items-center gap-2">
        <div className="flex flex-col gap-0.5">
          <input
            className={
              "bg-bg text-text font-normal text-xs rounded px-1 " +
              styles.inputcalendar
            }
            value={filterValue[0] ? filterValue[0] : min}
            type="date"
            min={preFilteredRows[preFilteredRows?.length - 1]?.values[id]}
            max={
              preFilteredRows[0]?.values[id] > max
                ? max
                : preFilteredRows[0]?.values[id]
            }
            onChange={(e) => {
              const val = e.target.value;

              setFilter([val, max]);
            }}
            placeholder={`Min (${min})`}
          />

          <input
            className={
              "bg-bg text-text font-normal text-xs rounded px-1 " +
              styles.inputcalendar
            }
            value={filterValue[1] ? filterValue[1] : max}
            type="date"
            min={
              preFilteredRows[preFilteredRows?.length - 1]?.values[id] < min
                ? min
                : preFilteredRows[preFilteredRows?.length - 1]?.values[id]
            }
            max={preFilteredRows[0]?.values[id]}
            onChange={(e) => {
              const val = e.target.value;

              setFilter([min, val]);
            }}
            placeholder={`Max (${max})`}
          />
        </div>
      </div>
    );
  };

  const betweenDate = useMemo(() => (rows, id, filterValue) => {
    return rows.filter(
      (row) =>
        row.values[id] >= filterValue[0] && row.values[id] <= filterValue[1]
    );
  });

  // ****************************************************************

  const defaultNumberSort = useMemo(() => (rowA, rowB, columnId) => {
    const valA = rowA.values[columnId];
    const valB = rowB.values[columnId];

    if (!isNaN(valA)) {
      // valA가 숫자인 경우
      if (isNaN(valB)) {
        // valA는 숫자 and valB는 숫자가 아닌경우
        switch (valB) {
          case "흑자전환":
            return -1;
          case "적자전환":
            return 1;
          case "적자확대":
            return 1;
          case "적자축소":
            return 1;
          default:
            return -1;
        }
      }
      return Number(valA) >= Number(valB) ? 1 : -1;
    } else {
      // valA가 숫자가 아닌 경우
      switch (valA) {
        case "흑자전환":
          return 1;
        case "적자전환":
          if (!isNaN(valB)) {
            return -1;
          }

        case "적자확대":
          if (valB == "적자전환") {
            return 1;
          } else {
            return -1;
          }
        case "적자축소":
          if (valB === "적자확대" || valB === "적자전환") {
            return 1;
          } else {
            return -1;
          }
        default:
          return -1;
      }
    }
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get(`http://stockinu.com:4000/api/updown`);
      setData(
        res.data.map((item) => {
          return { ...item, date: item.date.replace(/\./g, "-") };
        })
      );
      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ company_id, name }) => {
    setChartInfo({ company_id, name });
  };

  const columns = useMemo(
    () => [
      {
        accessor: "date",
        Header: "공시일",
        Filter: DateRangeColumnFilter,
        filter: betweenDate,
      },
      { accessor: "name", Header: "회사명", disableSortBy: true },
      {
        Header: "매출(억)",
        columns: [
          {
            accessor: "revenue",
            Header: "당기",
            disableFilters: true,
            Cell: ({ value }) => {
              return value
                ?.toFixed()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
          {
            accessor: "revenue_last",
            Header: "전기",
            disableFilters: true,
            Cell: ({ value }) => {
              return value
                ?.toFixed()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },

          {
            accessor: "revenue_growth",
            Header: "YoY",
            disableFilters: true,
            sortType: defaultNumberSort,
            Cell: ({ value }) => {
              return (
                <span
                  className={`${
                    value < 0 ? "text-text-minus" : "text-text-plus"
                  }`}
                >
                  {value}%
                </span>
              );
            },
          },
        ],
      },
      {
        Header: "영업이익",
        columns: [
          {
            accessor: "profit",
            Header: "당기",
            disableFilters: true,
            Cell: ({ value }) => {
              return value
                ?.toFixed()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },

          {
            accessor: "profit_last",
            Header: "전기",
            disableFilters: true,
            Cell: ({ value }) => {
              return value
                ?.toFixed()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },

          {
            accessor: "profit_growth",
            Header: "YoY",
            disableFilters: true,
            sortType: defaultNumberSort,
            Cell: ({ value }) => {
              if (isNaN(value)) {
                return value;
              }
              return (
                <span
                  className={`${
                    value < 0 ? "text-text-minus" : "text-text-plus"
                  }`}
                >
                  {value}%
                </span>
              );
            },
          },
        ],
      },
      {
        accessor: "reason",
        disableSortBy: true,
        Header: "변동사유",
      },
    ],
    []
  );

  return (
    <TableContent
      isLoading={isLoading}
      columns={columns}
      data={data}
      handleChartInfo={handleChartInfo}
    >
      {chartInfo?.company_id ? (
        <SharesStockChart
          company_id={chartInfo?.company_id}
          name={chartInfo?.name}
        />
      ) : (
        <></>
      )}
    </TableContent>
  );
}
