import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import GolfzonChart from "../../../components/stores/GolfzonChart";
import TableContent from "../../TableContent";

export default function Golfzon() {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const handleDuplicate = (dt) => {
    // 동일한 데이터 제거
    let temp = {};
    const reversed = (arr, asc) =>
      arr.sort((a, b) => {
        return asc
          ? Date.parse(a.date) - Date.parse(b.date)
          : Date.parse(b.date) - Date.parse(a.date);
      });

    const reversedData = reversed(dt, true).filter((item) => {
      const { date, ...prep } = item;
      if (JSON.stringify(temp) === JSON.stringify(prep)) {
        return false;
      } else {
        temp = { ...prep };
        return true;
      }
    });

    return reversed(reversedData, false);
  };
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("http://stockinu.com:4000/api/golfzons");
      setData(handleDuplicate(res.data));
      setIsLoading(false);
    })();
  }, []);

  const columns = useMemo(
    () => [
      { accessor: "date", Header: "일자", disableFilters: true },
      {
        accessor: "frs_shops",
        Header: "가맹점",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "non_frs_shops",
        Header: "비가맹점",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "total",
        Header: "총시스템",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "twovision",
        Header: "투비젼",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "twovisionplus",
        Header: "투비젼플러스",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "oldversion",
        Header: "구버전",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            row.values.total - row.values.twovision - row.values.twovisionplus
          );
        },
        disableSortBy: true,
      },
      {
        accessor: "gdr",
        Header: "gdr",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "gdr_shops",
        Header: "gdr비가맹",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "japan_shops",
        Header: "일본",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "china_shops",
        Header: "중국",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <>
      <TableContent
        isLoading={isLoading}
        columns={columns}
        data={data}
        disableControlPanel={true}
      >
        <GolfzonChart />
      </TableContent>
    </>
  );
}
