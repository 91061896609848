import React from "react";

export default function Main() {
  return (
    <div className="pt-5">
      <div className="text-2xl text-white font-black pl-5">
        Welcome to STOCK IN U
      </div>
      <div className="text-sm text-white pl-8 pt-2">
        본 사이트는 투자와 개발이라는 개인적인 관심사로부터 시작된 개인적인
        프로젝트이나, 개인 투자자들에게 도움이 되었으면 하는 마음으로 다양한
        기능을 확장중입니다.
      </div>

      <div className=" text-white pl-8 pt-8">
        <p className="text-lg font-bold text-cyan-200">✓ 임원주주변동상황 </p>
        <p className="pl-4 pt-1 ">
          내부자 거래 내역 공시를 매일 매일 업데이트합니다.
        </p>
        <p className="pl-4 pt-1 ">
          각 행을 클릭하시면, 해당 기업의 내수자 내역이 표시된 주가차트, 해당
          공시의 내부자의 장내 매수 히스토리가 표시됩니다.
        </p>
      </div>
      <div className="text-white pl-8 pt-6">
        <p className="text-lg font-bold text-cyan-200">✓ 실적변동공시</p>
        <p className="pl-4 pt-1 ">
          2022년의 실적 변동 공시를 매일 매일 업데이트 중입니다.
        </p>
      </div>
      <div className="text-white pl-8 pt-6">
        <p className="text-lg font-bold text-cyan-200">✓ 컨센서스변동</p>
        <p className="pl-4 pt-1 ">
          각 기업의 시장의 매출, 영업이익 컨센서스를 지속적으로
          모니터링중입니다.
        </p>
        <p className="pl-4 pt-1">
          테이블 상에 리스트업된 기업들은 전일 대비 컨센서스 변동이 있는 기업들
          내역입니다.
        </p>
        <p className="pl-4 pt-1">
          테이블의 각 행을 클릭하시면, 컨센서스 변동과 주가를 비교하는 차트를
          표시합니다.
        </p>
      </div>
      <div className="text-white pl-8 pt-6">
        <p className="text-lg font-bold text-cyan-200">✓ 수출통계</p>
        <p className="pl-4 pt-1 ">
          기업의 주요 제품의 월별 수출 통계를 모니터링 중입니다.
        </p>
        <p className="pl-4 pt-1">
          시기의 차이, HSCODE, 수출지역 등의 오류로 인해, 기업별 매출로 인식되는
          실제 수출 실적과는 차이가 있을 수 있음을 알려드립니다.
        </p>
      </div>
      <div className="text-white pl-8 pt-6">
        <p className="text-lg font-bold text-cyan-200">
          ✓ 가맹점추적/리뷰트렌드
        </p>
        <p className="pl-4 pt-1 ">
          개인적으로 관심있는 기업의 가맹점, 제품의 트렌드를 지속적으로
          추적중입니다. 모니터링을 원하시는 가맹점, 제품이 있으시면 위 메일로
          연락주시기 바랍니다.
        </p>
      </div>
    </div>
  );
}
