import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ConsensusChart from "../../components/ConsensusChart";
import SearchInput from "../../components/SearchInput";

import TableContent from "../TableContent";

export default function Consensus() {
  const [isLoading, setIsLoading] = useState(false);
  const [chartInfo, setChartInfo] = useState(null);
  const [data, setData] = useState([]);
  const [companylist, setCompanylist] = useState([]);
  const [{ today, last }, setDate] = useState({});

  useEffect(() => {
    (async () => {
      const fetched = await axios.get("http://stockinu.com:4000/api/company");

      setCompanylist(fetched.data);
    })();

    (async () => {
      setIsLoading(true);
      const today = new Date();
      const year = today.getFullYear().toString();

      let temp_data = [];

      let today_days = 0;
      let last_days = 1;
      while (true) {
        const { data: res_today } = await axios.get(
          `http://stockinu.com:4000/api/consensus/dateinterval/${today_days}/year/${
            year + "12"
          }`
        );
        const { data: res_last } = await axios.get(
          `http://stockinu.com:4000/api/consensus/dateinterval/${last_days}/year/${
            year + "12"
          }`
        );

        setDate({
          today: res_today[0].date.slice(5),
          last: res_last[0].date.slice(5),
        });

        const getGrowth = (curr, prev) => {
          if (curr >= 0) {
            if (prev >= 0) {
              return (curr / prev - 1) * 100;
            } else {
              return "흑자전환";
            }
          } else {
            if (prev >= 0) {
              return "적자전환";
            } else {
              if (curr > prev) {
                return "적자축소";
              } else {
                return "적자확대";
              }
            }
          }
        };

        res_today.forEach((today_item) => {
          res_last.forEach((last_item) => {
            if (
              today_item.company_id === last_item.company_id &&
              (today_item.revenue !== last_item.revenue ||
                today_item.operatingProfit !== last_item.operatingProfit)
            ) {
              const temp = {
                name: today_item.name,
                target_year: today_item.year,
                company_id: today_item.company_id,
                today_revenue: today_item.revenue,
                last_revenue: last_item.revenue,
                revenue_change: getGrowth(
                  today_item.revenue,
                  last_item.revenue
                ),
                today_operatingProfit: today_item.operatingProfit,
                last_operatingProfit: last_item.operatingProfit,
                operationProfit_change: getGrowth(
                  today_item.operatingProfit,
                  last_item.operatingProfit
                ),
                code: today_item.code,
                date: today_item.date,
              };

              temp_data.push(temp);
            }
          });
        });

        if (temp_data.length !== 0) {
          break;
        } else {
          today_days += 1;
          last_days += 1;
        }
      }

      setData(temp_data);

      setIsLoading(false);
    })();
  }, []);

  const handleChartInfo = ({ company_id, name }) => {
    setChartInfo({ company_id, name });
  };

  const defaultNumberSort = useMemo(() => (rowA, rowB, columnId) => {
    const valA = rowA.values[columnId];
    const valB = rowB.values[columnId];

    if (!isNaN(valA)) {
      // valA가 숫자인 경우
      if (isNaN(valB)) {
        return 1;
      }
      return Number(valA) >= Number(valB) ? 1 : -1;
    } else {
      switch (valA) {
        case "흑자전환":
          return 1;
        case "적자전환":
          return -1;
        case "적자확대":
          return -1;
        case "적자축소":
          if (valB === "적자확대" || valB === "적자전환") {
            return 1;
          } else {
            return -1;
          }
        default:
          return -1;
      }
    }
  });

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "회사명",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        accessor: "today_revenue",
        Header: `매출(${today})`,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        accessor: "last_revenue",
        Header: `매출(${last})`,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        accessor: "revenue_change",
        Header: "매출변동",
        disableFilters: true,

        Cell: ({ value }) => {
          if (isNaN(value)) {
            return value;
          }
          return (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value.toFixed(1)}%
            </span>
          );
        },
        type: "change",
        sortType: defaultNumberSort,
      },
      {
        accessor: "today_operatingProfit",
        Header: `OP(${today})`,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        accessor: "last_operatingProfit",
        Header: `OP(${last})`,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },

      {
        accessor: "operationProfit_change",
        Header: "OP변동",
        disableFilters: true,
        Cell: ({ value }) => {
          if (isNaN(value)) {
            return value;
          }
          return (
            <span
              className={`${value < 0 ? "text-text-minus" : "text-text-plus"}`}
            >
              {value.toFixed(1)}%
            </span>
          );
        },
        type: "change",
        sortType: defaultNumberSort,
      },
    ],
    [today, last]
  );

  return (
    <>
      <TableContent
        isLoading={isLoading}
        columns={columns}
        data={data}
        handleChartInfo={handleChartInfo}
      >
        <div className=" justify-between items-baseline flex">
          <SearchInput datalist={companylist} handleKeyword={handleChartInfo} />
        </div>

        {chartInfo?.company_id ? (
          <ConsensusChart
            company_id={chartInfo?.company_id}
            name={chartInfo?.name}
          />
        ) : (
          <></>
        )}
        {data ? (
          <span className="text-xs font-bold italic align-bottom pr-4 flex justify-end">
            {data[0]?.target_year.slice(0, 4)} 년 컨센서스 변동
          </span>
        ) : (
          ""
        )}

        {/* {JSON.stringify(data)} */}
      </TableContent>
    </>
  );
}
